import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

import {
  DIRECT_DEBIT_COLLECTION_TYPES,
  SDD_MANDATE_STATUS,
} from 'qonto/constants/direct-debit-collections';
import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';

export default class DirectDebitCollectionMandateModel extends Model {
  @service intl;

  @attr('string') status;
  @attr('string') uniqueMandateReference;
  @attr('string') creditorSchemeId;
  @attr('date') mandateSignatureDate;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_TYPES.CORE }) type;
  @attr('string', { defaultValue: 'recurring' }) recurrenceType;
  @attr('string') link;
  @attr('string') clientIban;
  @attr('string') clientIbanLastDigits;
  @attr('string') clientBic;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  clientSnapshot;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  organizationSnapshot;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  paymentInfo;

  @belongsTo('customer', { async: false, inverse: null }) client;
  @belongsTo('organization', { async: false, inverse: null }) organization;

  get isOneOff() {
    return this.paymentInfo.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF;
  }

  get isRecurring() {
    return !this.isOneOff;
  }

  get isPending() {
    return this.status === SDD_MANDATE_STATUS.PENDING;
  }

  get isApproved() {
    return this.status === SDD_MANDATE_STATUS.APPROVED;
  }

  get isDeclined() {
    return this.status === SDD_MANDATE_STATUS.DECLINED;
  }

  get isCanceled() {
    return this.status === SDD_MANDATE_STATUS.CANCELED;
  }
}
