import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

export default class BudgetTransactionModel extends Model {
  @attr activityTag;
  @attr amount;
  @attr counterpartyName;
  @attr emittedAt;
  @attr operationMethod;
  @attr status;

  @service intl;

  @belongsTo('membership', { async: false, inverse: null }) initiator;

  operationMethodTranslation = {
    biller: this.intl.t('transactions.operation-types.biller'),
    card: this.intl.t('transactions.operation-types.card'),
    cheque: this.intl.t('transactions.operation-types.cheque'),
    direct_debit: this.intl.t('transactions.operation-types.direct-debit'),
    pagopa_payment: this.intl.t('transactions.operation-types.pagopa-payment'),
    tax: this.intl.t('transactions.operation-types.tax'),
    transfer: this.intl.t('transactions.operation-types.transfer'),
  };

  get operationMethodName() {
    return (
      this.operationMethodTranslation[this.operationMethod] ??
      this.intl.t('transactions.operation-types.other')
    );
  }

  get initiatorMembershipId() {
    return this.belongsTo('initiator').id();
  }

  get initiatorMembership() {
    return this.belongsTo('initiator').value();
  }
}
