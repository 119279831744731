import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import RequestMultiTransferTransferValidations from 'qonto/validations/request-multi-transfer-transfer';

export default class RequestMultiTransferTransferModel extends Model.extend(
  RequestMultiTransferTransferValidations
) {
  @hasMany('attachment', { async: false, inverse: null }) attachments;
  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  @belongsTo('requestMultiTransfer', { async: true, inverse: 'transfers' }) requestMultiTransfer;

  @attr({ defaultValue: 'transfer' }) requestType;
  @attr beneficiaryIban;
  @attr beneficiaryBic;
  @attr beneficiaryName;
  @attr beneficiaryEmail;
  @attr amount;
  @attr amountCurrency;
  @attr reference;
  @attr activityTag;
  @attr notifyByEmail;

  @reads('savedAttachments.length') attachmentCount;
  @reads('requestMultiTransfer.initiator') initiator;
  @reads('requestMultiTransfer.approver') approver;
  @reads('requestMultiTransfer.status') status;
  @reads('requestMultiTransfer.operationType') operationType;
  @reads('requestMultiTransfer.note') note;
  @reads('requestMultiTransfer.processedAt') processedAt;
  @reads('requestMultiTransfer.createdAt') createdAt;
  @reads('requestMultiTransfer.once') once;
  @reads('requestMultiTransfer.later') later;
  @reads('requestMultiTransfer.weekly') weekly;
  @reads('requestMultiTransfer.monthly') monthly;
  @reads('requestMultiTransfer.hasPastDate') hasPastDate;
  @reads('requestMultiTransfer.declinedNote') declinedNote;

  @service abilities;

  get nonEmptyAttachments() {
    return this.attachments.filter(item => get(item, 'file'));
  }

  get attachmentsFiles() {
    return this.nonEmptyAttachments.map(item => get(item, 'file'));
  }

  get savedAttachments() {
    return this.attachments.filter(item => get(item, 'isNew') === false);
  }

  get hasRequestMultiTransfer() {
    return this.belongsTo('requestMultiTransfer').value();
  }

  get dasherizedRequestType() {
    return 'transfer';
  }

  @dependentKeyCompat
  get shouldValidateActivityTag() {
    return !this.abilities.can('assign category');
  }
}
