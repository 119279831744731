import Model, { attr, belongsTo } from '@ember-data/model';

export default class OrganizationKybDetail extends Model {
  @attr('string') annualTurnover;
  @attr('number') estimatedAnnualTurnover;
  @attr('string') yearlyTransactionVolume;
  @attr('number') maxSinglePaymentAmount;
  @attr natureOfOperations;
  @belongsTo('organization', { async: false, inverse: 'kybDetails' }) organization;
}
