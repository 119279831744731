import { assert } from '@ember/debug';

import { compact } from 'ember-metrics/-private/utils/object-transforms';
import removeFromDOM from 'ember-metrics/-private/utils/remove-from-dom';
import BaseAdapter from 'ember-metrics/metrics-adapters/base';
import window from 'ember-window-mock';

export const SEGMENT_READY_EVENT = 'segmentReady';

export default class LocalSegment extends BaseAdapter {
  toStringExtension() {
    return 'LocalSegment';
  }

  init() {
    this.install();
  }

  install() {
    let config = { ...this.config };
    let segmentKey = config.key;
    let options = config.options ?? {};

    assert(
      `[ember-metrics] You must pass a valid \`key\` to the ${this.toString()} adapter`,
      segmentKey
    );

    // start of segment loading snippet, taken here:
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-1-copy-the-snippet

    /* eslint-disable no-console */

    // Create a queue, but don't obliterate an existing one!
    let analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'addSourceMiddleware',
      'addDestinationMiddleware',
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function () {
        let args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      let key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key, options) {
      // Create an async script element based on your key.
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

      script.onload = function () {
        window.dispatchEvent(new Event(SEGMENT_READY_EVENT));
      };
      script.onerror = function () {
        window.analyticsFailedToLoad = true;
      };

      // Insert our script next to the first script element.
      let first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account.

    // NOTE: original Segment adapter from ember-metrics does not support 'options' param

    analytics.load(segmentKey, options);
    // end of segment loading snippet
  }

  alias(options = {}) {
    let compactedOptions = compact(options);
    let { alias, original } = compactedOptions;

    if (original) {
      window.analytics.alias(alias, original);
    } else {
      window.analytics.alias(alias);
    }
  }

  identify(options = {}) {
    let compactedOptions = compact(options);
    let { distinctId } = compactedOptions;
    let integrations = compactedOptions.integrations ?? {};

    delete compactedOptions.distinctId;
    delete compactedOptions.integrations;

    window.analytics.identify(distinctId, compactedOptions, { integrations });
  }

  trackEvent(options = {}) {
    let compactedOptions = compact(options);
    let { event } = compactedOptions;
    let integrations = compactedOptions.integrations ?? {};

    delete compactedOptions.event;
    delete compactedOptions.integrations;

    window.analytics.track(event, compactedOptions, { integrations });
  }

  trackPage(options = {}) {
    let compactedOptions = compact(options);
    let { page } = compactedOptions;
    let integrations = compactedOptions.integrations ?? {};

    delete compactedOptions.page;
    delete compactedOptions.integrations;

    window.analytics.page(page, compactedOptions, {}, { integrations });
  }

  uninstall() {
    removeFromDOM('script[src*="segment.com"]');

    delete window.analytics;
  }

  willDestroy() {
    this.uninstall();
  }
}
