import { attr, belongsTo } from '@ember-data/model';

import { equal, reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { TRANSACTION_FEE_OPERATION_SUBTYPES } from 'qonto/constants/transactions';
import Subject from 'qonto/models/subject';

export default class WalletToWalletModel extends Subject {
  @attr('number') amount;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string') operationType;
  @attr('string') operationSubtype;
  @attr('string') reference;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  enrichmentData;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  @equal('operationType', 'fee') isFee;
  @equal('operationType', 'credit_note') isCreditNote;

  @reads('operationSubtype') feeType;

  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.PHYSICAL_CARD) isPhysicalCard;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.VIRTUAL_CARD) isVirtualCard;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SEPA_INBOUND_TRANSFER)
  isSepaInboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SEPA_OUTBOUND_TRANSFER)
  isSepaOutboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.ATM) isAtm;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_CARD) isFxCard;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_INBOUND_TRANSFER)
  isFxInboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER)
  isFxOutboundTransfer;
  @equal('operationSubtype', TRANSACTION_FEE_OPERATION_SUBTYPES.SUBSCRIPTION) isSubscription;

  get isInternationalOutFee() {
    return [
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_NON_STANDARD_CURRENCY,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_STANDARD_CURRENCY,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_OUR,
      TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_SHA,
    ].includes(this.operationSubtype);
  }

  get isSDDCollectionFee() {
    return [
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_SEND,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_RETURN,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND,
      TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND_LATE,
    ].includes(this.operationSubtype);
  }
}
