import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

export default class HubNotificationRuleModel extends Model {
  @attr ibans;
  @attr trigger;
  @attr field;
  @attr filter;
  @attr value;
  @attr channelId;
  @attr channelName;

  @belongsTo('hub-connection', { async: false, inverse: 'rules' }) connection;

  @waitFor
  async estimate() {
    return await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
      path: 'estimate',
      data: { rule: this.serialize() },
    });
  }
}
