import { modifier } from 'ember-modifier';
import window from 'ember-window-mock';

export default modifier(
  function (element, [callback], options) {
    if (!('IntersectionObserver' in window)) return;

    let intersectionObserver = new IntersectionObserver(callback, options);
    intersectionObserver.observe(element);
    return () => intersectionObserver.unobserve(element);
  },
  {
    eager: false,
  }
);
