import Model, { attr, belongsTo } from '@ember-data/model';

import CURRENCIES from 'qonto/constants/currencies';

export default class CreditNoteModel extends Model {
  @attr('number') amount;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string') reference;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  // Temporary until deletion
  isCreditNote = true;
}
