import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class SwiftIncomeModel extends Subject {
  @attr amount;
  @attr amountCurrency;
  @attr localAmount;
  @attr localAmountCurrency;
  @attr exchangeRate;
  @attr emitterAccountIdentifier;
  @attr emitterBankIdentifier;
  @attr emitterNameAddress;
  @attr reference;

  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;
}
