import Model, { attr, belongsTo } from '@ember-data/model';

export default class RequestDirectDebitCollectionModel extends Model {
  @attr('string') debitorName;
  @attr('string') debitorIban;
  @attr('string') uniqueMandateReference;
  @attr('string') activityTag;
  @attr('string') note;
  @attr('string') reference;
  @attr amount;
  @attr('date') scheduledDate;
  @attr('date') mandateSignatureDate;

  @belongsTo('request-multi-direct-debit-collection', {
    async: false,
    inverse: 'requestDirectDebitCollections',
  })
  requestMultiDirectDebitCollection;
}
