import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import { bool, equal, or } from 'macro-decorators';

import Subject from 'qonto/models/subject';
import cmc7Splitter from 'qonto/utils/cmc7-splitter';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import Validations from 'qonto/validations/check';

export default class CheckModel extends Subject.extend(Validations) {
  @service networkManager;

  @attr('string') sequentialId;
  @attr('string') initiatorId;

  @attr('string') emitterName;
  @attr('number') amount;
  @attr('string') amountCurrency;
  @attr('string') shippingAddress;
  @attr('string') rlmc;

  // Here, cmc7 is the 'real' attribute sent and received from/to backend
  @attr cmc7;

  // For UI purpose cmc7 is splitted in 3 parts, and joined in check serializer
  @attr('string') cmc7a;
  @attr('string') cmc7b;
  @attr('string') cmc7c;

  @attr('number') creditDelayDays;
  @attr('string') status;
  @attr('string') declinedReason;
  @attr emittedDate;
  @attr('date') receivedAt;
  @attr('date') declinedAt;
  @attr('date') refundedAt;
  @attr('date') canceledAt;
  @attr('date') validatedAt;
  @attr('date') createdAt;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  enrichmentData;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('membership', { async: false, inverse: null }) initiator;

  get checkNumber() {
    return this.cmc7 ? cmc7Splitter(this.cmc7, ' - ') : '';
  }

  get isDeclinedAndNotReceived() {
    return this.declined && this.declinedReason === 'not_received';
  }

  get isOtherThanNotReceived() {
    return this.declinedReason !== 'not_received';
  }

  @bool('initiatorId') isCreatedByUser;
  @or('declined', 'refunded', 'canceled') ghost;
  @or('received', 'validated', 'declined', 'refunded') hasArrived;
  @or('validated', 'declined', 'refunded') hasResponse;

  @equal('status', 'pending') pending;
  @equal('status', 'validated') validated;
  @equal('status', 'declined') declined;
  @equal('status', 'refunded') refunded;
  @equal('status', 'received') received;
  @equal('status', 'canceled') canceled;

  get avatarInfo() {
    let checkSVG = `/icon/feature/feature_check-m.svg`;

    let icon = 'status_cancelled';

    if (this.pending || this.received) {
      icon = 'status_processing';
    } else if (this.validated) {
      icon = 'status_approved';
    }

    return {
      mediumLogo: this.enrichmentData?.logo?.medium ?? checkSVG,
      smallLogo: this.enrichmentData?.logo?.small ?? checkSVG,
      icon,
    };
  }

  @waitFor
  async confirm() {
    let data = { check: this.serialize() };
    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'confirm',
        data,
      });
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        if (errors.amount_cents) {
          errors.amount = errors.amount_cents;
          delete errors.amount_cents;
        }
        this.networkManager.errorModelInjector(this, errors);
      }
      throw error;
    }
  }
}
