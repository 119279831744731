import { modifier } from 'ember-modifier';

import { debounce } from 'qonto/utils/raf';

export default modifier(
  function mutationObserver(element, params, { onChange }) {
    let prevValue;

    let listener = debounce(() => {
      let scrolledTop = element.scrollTop === 0;

      if (prevValue !== scrolledTop) {
        onChange(scrolledTop);
      }

      prevValue = scrolledTop;
    });

    listener();

    element.addEventListener('scroll', listener, { passive: true });
    return () => {
      element.removeEventListener('scroll', listener, { passive: true });
    };
  },
  { eager: false }
);
