import Model, { attr, belongsTo } from '@ember-data/model';

export default class ExternalBankRecommendationModel extends Model {
  @attr('string') status;
  @attr('string') type;
  @attr('string') counterpartyName;

  @belongsTo('external-bank-recommendations-bundle', { async: false, inverse: 'recommendations' })
  externalBankRecommendationsBundle;
}
