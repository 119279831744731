import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isArray } from '@ember/array';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import { formatIban, formatSortCode } from 'qonto/utils/format-input';
import BeneficiaryValidations from 'qonto/validations/beneficiary';

export default class BeneficiaryModel extends Model.extend(BeneficiaryValidations) {
  @service networkManager;

  @attr('string') accountNumber;
  @attr('string', { defaultValue: 'iban' }) accountType;
  @attr('string', { defaultValue: 'other_expense' }) activityTag;
  @attr('string') bic;
  @attr('string', { defaultValue: 'FR' }) country;
  @attr('string') creditorIdentifier; // used for mandates
  @attr('string') currency;
  @attr('file') defaultAvatar;
  @attr('file') defaultAvatarThumb;
  @attr('string', { defaultValue: null }) email;
  @attr('boolean', { defaultValue: false }) fx;
  @attr('string', { defaultValue: '' }) iban;
  @attr('string') name;
  @attr('boolean') qontoBankAccount;
  @attr('string') routingNumber;
  @attr('string', { defaultValue: '' }) sortCode;
  @attr('number') transferTransactionsCount;
  @attr('number') vatRate;
  @attr('number') vatAmount;
  @attr('boolean') trusted;
  @attr('boolean', { defaultValue: false }) hidden;
  @attr() creditBankAccountId;
  @attr('hash') address;

  @tracked isAddressRequired = false;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @hasMany('label', { async: false, inverse: 'beneficiary' }) labels;

  get isIBANObfuscated() {
    if (!this.iban) {
      return false;
    }
    return /^(?:••••|xxxx)/i.test(this.iban);
  }

  get displayAccountNumber() {
    switch (this.accountType) {
      case ACCOUNT_TYPES.IBAN:
        return formatIban(this.iban);

      case ACCOUNT_TYPES.BANK_CODE:
        return `${this.accountNumber} · ${formatSortCode(this.sortCode)}`;

      case ACCOUNT_TYPES.BIC_SWIFT:
        return `${this.accountNumber} · ${this.bic}`;

      case ACCOUNT_TYPES.ABA:
        return `${this.accountNumber} · ${this.routingNumber}`;

      default:
        return undefined;
    }
  }

  @waitFor
  async confirm() {
    let data = { beneficiary: this.serialize() };
    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'confirm',
        data,
      });
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        // Internally ActiveModelAdapter tries to convert ActiveModelAdapter formatted errors into JSONAPI formatted errors
        // But we are sending validation errors JSONAPI formatted, the result is the original JSONAPI error is in the error.detail
        let errors = error.errors.map(e => {
          let error = e.detail;
          if (isArray(error.detail)) error.detail = error.detail[0]; // BE sends string array not string
          // errorsArrayToHash expects `/data/attributes/iban` but we correctly have `/beneficiary/iban`
          error.source.pointer = `/data/attributes/${error.source.pointer.split('/').pop()}`;
          return error;
        });
        errors = errorsArrayToHash(errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async saveCounterparty() {
    let data = { beneficiary: this.serialize() };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'accounting_info', data });
      this.store.pushPayload(response);
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async updateActivityTag(activityTag) {
    let data = { beneficiary: { activity_tag: activityTag } };
    let response = await apiAction(this, { method: 'PUT', path: 'activity', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async markAsUntrusted() {
    let response = await apiAction(this, { method: 'PATCH', path: 'untrust' });
    this.store.pushPayload(response);
  }

  @waitFor
  async markAsTrusted() {
    let response = await apiAction(this, { method: 'PATCH', path: 'trust' });
    this.store.pushPayload(response);
  }
}
