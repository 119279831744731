import { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import Subject from 'qonto/models/subject';

export default class NrcPaymentModel extends Subject {
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @attr('string') canceledAt;
  @attr('string') declineReason;
  @attr('string') executionDate;
  @attr('string') nrc;
  @attr('string') receiptUrl;
  @attr('string') status;
  @attr('string') statusGroup;
  @attr('string') taxCode;
  @attr('string') taxDescription;
  @attr('string') type;
  @attr('string') acronym;
  @attr('string') documentId;
  @attr('string') fiscalYear;
  @attr('boolean') isPayingForSomeoneElse;
  @attr('boolean') isPaymentInInstallments;
  @attr('boolean') isPreDeclaration;
  @attr('string') legalName;
  @attr('string') period;
  @attr('string') receiptCode;
  @attr amount;

  get avatarInfo() {
    let activityTagSVG = `/icon/category/tax-m.svg`;

    return {
      mediumLogo: activityTagSVG,
      smallLogo: activityTagSVG,
      hasBorder: false,
    };
  }

  @waitFor
  async createPayment() {
    let payment = this.serialize();

    let data = {
      payment: {
        acronym: payment.acronym === '' ? null : payment.acronym,
        document_id: payment.document_id,
        fiscal_year: payment.fiscal_year,
        is_paying_for_someone_else: payment.is_paying_for_someone_else,
        is_payment_in_installments: payment.is_payment_in_installments,
        is_pre_declaration: payment.is_pre_declaration,
        legal_name: payment.legal_name,
        period: payment.period,
        receipt_code: payment.receipt_code,
        amount: payment.amount,
        bank_account_id: payment.bank_account_id,
        tax_code: payment.tax_code,
      },
    };

    let response = await apiAction(this, {
      method: 'POST',
      data,
    });
    return response;
  }
}
