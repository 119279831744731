import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

import { KYC_STATUS } from 'qonto/constants/membership';

export default class IdentitiesKycModel extends Model {
  @attr('string') kycStatus;
  @attr('string') providerName;

  @equal('kycStatus', KYC_STATUS.ACCEPTED) kycAccepted;
  @equal('kycStatus', KYC_STATUS.PENDING) kycPending;
  @equal('kycStatus', KYC_STATUS.REFUSED) kycRefused;
  @equal('kycStatus', KYC_STATUS.WAITING_DOCUMENT) kycWaitingDocument;

  @equal('providerName', 'fourthline') isProviderFourthline;
}
