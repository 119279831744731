import { Resize } from 'ember-animated/motions/resize';

class OverflowHiddenResize extends Resize {
  *animate() {
    this.sprite.applyStyles({
      overflow: 'hidden',
    });
    yield* super.animate();
  }
}

export default function overflowHiddenResize(sprite, opts) {
  return new OverflowHiddenResize(sprite, opts);
}
