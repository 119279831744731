// @ts-nocheck
import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class CardAcquirerPayoutModel extends Subject {
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @attr('number') amount;
  @attr('string') amountCurrency;
  @attr('string') reference;
  @attr('string') status;
}
