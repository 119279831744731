import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import Validations from 'qonto/validations/label';

export default class LabelModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  createdAt;

  @belongsTo('label-list', { async: false, inverse: 'labels' }) labelList;
  @belongsTo('organization', { async: false, inverse: 'labels' }) organization;
  @hasMany('transaction', { async: false, inverse: 'labels' }) transaction;
  @hasMany('beneficiary', { async: false, inverse: 'labels' }) beneficiary;
}
