import { modifier } from 'ember-modifier';

let handler = function (event) {
  event.target.play();
};

export default modifier(function (element) {
  element.muted = true;
  element.addEventListener('mouseover', handler);
  return () => element.removeEventListener('mouseover', handler);
});
