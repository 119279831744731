import { attr, belongsTo, hasMany } from '@ember-data/model';

import Assignable from 'qonto/models/assignable';

export default class TeamModel extends Assignable {
  @attr name;
  @attr('number') activeMembershipsCount;
  @attr('number') membershipsCount;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // active team managers
  @hasMany('membership', { async: false, inverse: null }) teamManagers;
}
