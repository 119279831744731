import { attr, belongsTo } from '@ember-data/model';

import BaseReceivableInvoiceModel from './base';

export default class BaseInvoiceDocumentModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  @attr purchaseOrder;

  /** @type {Payment} */
  @belongsTo('receivable-invoice/payment', { async: false, inverse: null }) payment;

  /** @type {BankAccount} */
  @belongsTo('bankAccount', { async: true, inverse: null }) bankAccount;
}
