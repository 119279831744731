import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

export default class IntegrationModel extends Model {
  @service store;

  @attr('string') login;
  @attr('string') secretKey;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  @waitFor
  async regenerateKey() {
    let response = await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
    });
    this.store.pushPayload(response);
  }
}
