import Model, { attr, belongsTo } from '@ember-data/model';

export default class DiscountModel extends Model {
  @attr('string') finishOn;
  @attr('string') recurrenceOn;
  @attr('string') voucherCode;
  @attr('string') voucherUsed;
  @attr('string') voucherType;
  @attr('string') voucherFeeType;
  @attr('number') voucherAmount;
  @attr('number') voucherMonthDuration;
  @attr('string') voucherAmountCurrency;
  @attr voucherRestrictions;
  @attr('boolean') discountValid;

  @belongsTo('organization', { async: false, inverse: null }) organization;
}
