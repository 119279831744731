import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import Validations from 'qonto/validations/label-list';

export default class LabelListModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') color;
  @attr('number') rank;

  @belongsTo('organization', { async: false, inverse: 'labelLists' }) organization;
  @hasMany('label', { async: false, inverse: 'labelList' }) labels;
}
