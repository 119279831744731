import Model, { attr, belongsTo } from '@ember-data/model';

import Validations from 'qonto/validations/referral';

export default class ReferralModel extends Model.extend(Validations) {
  @attr('string') code;
  @attr('string') status;
  @attr('string') email;
  @attr('string') referreeName;
  @attr('number') rewardAmount;
  @attr('number') rewardAmountCents;
  @attr('string') rewardAmountCurrency;

  @attr('date') registeredAt;
  @attr('date') rewardedAt;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  createdAt;

  @belongsTo('organization', { async: false, inverse: null }) referree;
  @belongsTo('organization', { async: false, inverse: null }) referrer;
}
