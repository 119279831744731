import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import {
  DIRECT_DEBIT_COLLECTION_STATUS,
  DIRECT_DEBIT_COLLECTION_STATUS_LEVELS,
  DIRECT_DEBIT_COLLECTION_STATUS_REASON,
  DIRECT_DEBIT_COLLECTION_TYPES,
} from 'qonto/constants/direct-debit-collections';

export const getStatusLabel = (value, intl) => {
  let statusMap = {
    [DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED]: 'completed',
    [DIRECT_DEBIT_COLLECTION_STATUS.CANCELED]: 'canceled',
    [DIRECT_DEBIT_COLLECTION_STATUS.DECLINED]: 'declined',
    [DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED]: 'refunded',
    [DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD]: 'on-hold',
  };

  if (!statusMap[value]) {
    return '';
  }

  return intl.t(`sdd-collections.status.${statusMap[value]}`);
};

export default class DirectDebitCollectionModel extends Model {
  @service intl;

  @attr() amount;
  @attr('string') debitorIban;
  @attr('string') debitorName;
  @attr('string') debitorBic;
  @attr('string') reference;
  @attr('date') collectionDate;
  @attr('date') executedAt;
  @attr('string') slug;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED }) status;
  @attr('string') statusReason;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_TYPES.CORE }) type;
  @attr('string') uniqueMandateReference;
  @attr('string', { defaultValue: ACTIVITY_TAGS.OTHER_INCOME }) activityTag;
  @attr('string') rTransactionType;
  @attr('date') rTransactionExecutedAt;
  @attr('string') originalTransactionId;
  @attr() onHoldAmount;
  @attr('string') onHoldRate;
  @attr('string') onHoldReleaseDate;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  @belongsTo('transaction', { async: false, inverse: null }) transaction;
  @belongsTo('transaction', { async: false, inverse: null }) rTransaction;

  get isStatusReasonAmountLimitReached() {
    return this.statusReason === DIRECT_DEBIT_COLLECTION_STATUS_REASON.AMOUNT_LIMIT_REACHED;
  }

  get isStatusReasonEligibilityLost() {
    return this.statusReason === DIRECT_DEBIT_COLLECTION_STATUS_REASON.ELIGIBILITY_LOST;
  }

  get isCanceled() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.CANCELED;
  }

  get isDeclined() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.DECLINED;
  }

  get isCompleted() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED;
  }

  get isRefunded() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED;
  }

  get isOnHold() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD;
  }

  get statusDisplayInfo() {
    return {
      title: getStatusLabel(this.status, this.intl),
      level: DIRECT_DEBIT_COLLECTION_STATUS_LEVELS[this.status],
    };
  }

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.isCanceled || this.isDeclined || this.isRefunded) {
      avatarStatusIconSrc = 'status_cancelled';
    } else {
      avatarStatusIconSrc = 'status_approved';
    }

    return {
      mediumLogo: activityTagSVG,
      smallLogo: activityTagSVG,
      icon: avatarStatusIconSrc,
    };
  }

  get statusFullText() {
    switch (this.status) {
      case DIRECT_DEBIT_COLLECTION_STATUS.DECLINED: {
        return this.intl.t('sdd-collections.status.declined-full');
      }
      case DIRECT_DEBIT_COLLECTION_STATUS.CANCELED: {
        return this.intl.t('sdd-collections.status.canceled-full');
      }
      case DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED: {
        return this.intl.t('sdd-collections.status.completed-full');
      }

      case DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED: {
        return this.intl.t('sdd-collections.status.refunded-full');
      }

      case DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD: {
        return this.intl.t('sdd-collections.status.on-hold-full');
      }
    }
  }
}
