import Model, { attr, belongsTo } from '@ember-data/model';

export default class BulkBeneficiaryModel extends Model {
  @belongsTo('multiBeneficiary', { async: true, inverse: 'beneficiaries' }) multiBeneficiary;
  @belongsTo('organization', { async: true, inverse: null }) organization;

  @attr('string') activityTag;
  @attr('string') bic;
  @attr('string') email;
  @attr('string', { defaultValue: '' }) iban;
  @attr('string') name;
  @attr('boolean', { defaultValue: true }) selected;
}
