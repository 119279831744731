import Model, { attr, belongsTo } from '@ember-data/model';

import dayjs from 'dayjs';

export default class FlexKybModel extends Model {
  @attr('date') startDate;
  @attr('date') endDate;
  @attr('date') additionalPeriodEndDate;
  @attr('date') deactivationDueDate;
  @attr status;
  @belongsTo('organization', { async: true, inverse: 'flexKyb' }) organization;

  /**
   * Difference in days between `today` and `currentEndDate`.
   * Returns 1 when `today` is after the `currentEndDate`.
   * Starts from 1 instead of 0.
   */
  getRemainingDays(today, currentEndDate) {
    if (today >= currentEndDate) {
      return 1;
    }

    // This will ignore DST and only measure exact 24-hour periods
    return (Math.floor(dayjs(currentEndDate).diff(today, 'hour') / 24) | 0) + 1;
  }
}
