import Model, { attr, belongsTo } from '@ember-data/model';

import { bool } from 'macro-decorators';

import { MEMBER_STATUS } from 'qonto/constants/membership';
import titleize from 'qonto/utils/titleize';

export default class SuggestedInvitationModel extends Model {
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') email;
  @attr('string') avatarUrl;
  @attr('string') provider; // nullable for old suggested
  @attr('string') department; // nullable for old suggested
  @attr('string') jobTitle; // nullable for old suggested

  @bool('provider') importedFromHR;

  // Useful for backward compatibility with old suggested members.
  // When the previous implementation is deleted, these fields will also be deleted
  status = MEMBER_STATUS.INVITABLE;
  invitable = true;
  suggested = true;

  @belongsTo('organization', { async: true, inverse: 'suggested-invitation' }) organization;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();

    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }

    if (firstName) {
      return titleize(firstName);
    }

    if (lastName) {
      return titleize(lastName);
    }
  }
}
