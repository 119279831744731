import Model, { attr } from '@ember-data/model';

export default class F24InailTaxItemModel extends Model {
  @attr branchCode;
  @attr companyCode;
  @attr checkCode;
  @attr referenceNumber;
  @attr taxType;
  @attr('number') taxAmount;
  @attr('number') compensationAmount;
}
