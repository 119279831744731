import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';

import ExportTemplateValidations from 'qonto/validations/export-template';

export default class ExportTemplateModel extends Model.extend(ExportTemplateValidations) {
  @service intl;

  @attr type; // 'default' or 'custom'

  // only 'default' export templates
  @attr slug;

  // only 'custom' export templates
  @attr name;
  @attr format;
  @attr separator;
  @attr('boolean') exportFullDataSet;
  @attr creatorName;
  @attr({ defaultValue: () => [] }) columns;

  get isDefault() {
    return this.type === 'default';
  }

  get translationKey() {
    if (this.isDefault) {
      return this.slug.split('_').at(-1);
    }

    return this.id;
  }

  get formId() {
    if (this.isDefault) {
      if (this.hasFormatSelection) {
        return this.slug.split('_').at(-1);
      }
      return this.slug;
    }

    return this.id;
  }

  get hasFormatSelection() {
    return [
      'csv_extended',
      'xls_extended',
      'csv_simple',
      'xls_simple',
      'standard_mt940',
      'datev_mt940',
    ].includes(this.slug);
  }

  get translatedName() {
    if (!this.isDefault) {
      return;
    }

    let key = `transactions.exports.export.export-type.${this.translationKey}.title`;
    return this.intl.exists(key) ? this.intl.t(key) : this.name;
  }

  get translatedDescription() {
    if (!this.isDefault) {
      return;
    }

    let key = `transactions.exports.export.export-type.${this.translationKey}.description`;
    return this.intl.exists(key) ? this.intl.t(key) : this.name;
  }

  get columnsById() {
    let columnsMap = new Map();

    this.columns?.forEach(column => columnsMap.set(column.id, column));

    return columnsMap;
  }
}
