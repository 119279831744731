import { attr, belongsTo } from '@ember-data/model';

import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import Subject from 'qonto/models/subject';

export default class IncomeModel extends Subject {
  @attr('number') amount;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string') emitterBic;
  @attr('string') emitterIban;
  @attr('string') emitterIdentification;
  @attr('string') emitterName;
  @attr('string') reference;
  @attr('boolean', { defaultValue: true }) isIBANAccount;
  @attr('string', { defaultValue: 'iban' }) accountType;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;

  @reads('emitterBic') bic;
  @reads('emitterIban') iban;

  get isIBANObfuscated() {
    return /^(?:••••|xxxx)/i.test(this.iban);
  }
}
