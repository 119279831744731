import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import CURRENCIES from 'qonto/constants/currencies';
import { getCurrentParisDateString } from 'qonto/utils/date';
import TransferValidations from 'qonto/validations/transfer';

export default class BulkTransferModel extends Model.extend(TransferValidations) {
  @service networkManager;
  @service organizationManager;
  @service store;

  @attr('boolean') notifyByEmail;
  @attr('boolean', { defaultValue: true }) selected;
  @attr('string') activityTagCode;
  @attr('number') bulkId;
  @attr('string') activityTag;
  @attr('string') sequentialId;
  @attr('string') localAmountCurrency;
  @attr('string', { defaultValue: '' }) reference;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string', { defaultValue: 'scheduled' }) operationType;
  @attr('string', { defaultValue: ACCOUNT_TYPES.IBAN }) accountType;
  @attr('number', { defaultValue: 0 }) amount;
  @attr('date') createdAt;
  @attr('string', { defaultValue: getCurrentParisDateString }) scheduledDate;
  @attr() name;
  @attr() email;
  @attr({ defaultValue: '' }) iban;
  @attr() bic;

  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  @belongsTo('multiTransfer', { async: true, inverse: 'transfers' }) multiTransfer;

  @hasMany('attachment', { async: true, inverse: null }) attachments;

  originalBeneficiaryEmail = null;

  @reads('attachments.content.0') attachment;

  get savedAttachments() {
    return this.attachments.filter(attachment => !attachment.isNew);
  }

  get attachmentCount() {
    return this.savedAttachments.length;
  }

  get isScheduled() {
    return this.operationType === 'scheduled';
  }

  get shouldHaveAttachments() {
    let isAboveMaxAmount = amount => {
      let maxAmountInCents =
        this.organizationManager.organization.transferSettings?.max_amount_without_attachment_cents;
      let maxAmount = maxAmountInCents / 100;

      return amount >= maxAmount;
    };

    return isAboveMaxAmount(this.amount);
  }

  get attachmentsFiles() {
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  get hasMultiTransfer() {
    return Boolean(this.belongsTo('multiTransfer').value());
  }
}
