import Model, { attr } from '@ember-data/model';

export default class BulkActionModel extends Model {
  @attr('string') class;
  @attr('string') organizationId; // deprecated
  @attr('string') bankAccountId;

  @attr ids;
  @attr fields;
}
