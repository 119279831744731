import Model, { attr, hasMany } from '@ember-data/model';

export default class DocumentCollectionProcessModel extends Model {
  @attr reason;
  @attr status;
  @attr subjectId;
  @attr subjectType;

  @hasMany('document-collection-required-document', {
    async: false,
    inverse: 'documentCollectionProcess',
  })
  requiredDocuments;
}
