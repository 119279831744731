import Model, { attr } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

export default class OtpModel extends Model {
  @attr('string') token;
  @attr('string') phoneNumber;
  @attr('string') context; // Context for triggering a sms - basically "setup" or "action"
  @attr triggerToken;

  @waitFor
  async configure() {
    let data = { otp: { phone_number: this.phoneNumber, token: this.token } };
    return await apiAction(this, { method: 'POST', path: 'sms', data });
  }

  @waitFor
  async activate() {
    let data = { otp: { token: this.token } };
    return await apiAction(this, { method: 'POST', path: 'sms/activate', data });
  }

  @waitFor
  async triggerSmsWithoutToken(actionType) {
    let data = { otp: { context: this.context } };
    let requestType = 'triggerSMS';
    if (actionType) {
      data.action_type = actionType;
    }

    return await apiAction(this, { method: 'POST', path: 'sms/trigger', requestType, data });
  }

  @waitFor
  async triggerSmsWithToken() {
    let data = { otp: { context: this.context, trigger_token: this.triggerToken } };
    return await apiAction(this, { method: 'POST', path: 'sms/trigger_with_token', data });
  }

  triggerSms({ context, triggerSmsToken, actionType }) {
    this.context = context;

    if (triggerSmsToken) {
      this.triggerToken = triggerSmsToken;

      return this.triggerSmsWithToken();
    }

    return this.triggerSmsWithoutToken(actionType);
  }
}
