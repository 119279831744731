import Model, { attr, belongsTo } from '@ember-data/model';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import { apiAction } from '@mainmatter/ember-api-actions';

import HRIS_INTEGRATION_STATUS from 'qonto/constants/hris-integration';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

const LOCAL_STORAGE_CONNECTING_PREFIX = 'hris-connecting-';

export class HrisConnection {
  @service komboConnect;
  @service organizationManager;
  @service userManager;
  @service segment;
  @service store;

  static create(owner, partnerName) {
    return new HrisConnection(owner, partnerName).init();
  }

  constructor(owner, partnerName) {
    setOwner(this, owner);
    this.provider = partnerName;
  }

  get _storageConnectingKey() {
    return `${LOCAL_STORAGE_CONNECTING_PREFIX}${this.integration.id}`;
  }

  get hasIntegration() {
    return Boolean(
      this.integration &&
        (this.integration.status === HRIS_INTEGRATION_STATUS.CONNECTED ||
          this.integration.status === HRIS_INTEGRATION_STATUS.SYNCED ||
          safeLocalStorage.getItem(this._storageConnectingKey)) &&
        !this.integration.isNew &&
        !this.integration.isDeleted
    );
  }

  async init() {
    this.integration = await this.store
      .query('hris-integration', {
        filters: { provider: this.provider },
      })
      .then(([integration]) => integration);
    return this;
  }

  async setup(onKomboSuccess) {
    await this.createOrRegenerateLinkUrl();
    this.showKomboConnectModal()
      .then(onKomboSuccess)
      .catch(() => {
        /* user has closed modal */
      });
  }

  async createOrRegenerateLinkUrl() {
    let integrationName = this.provider;
    this.segment.track('connect_integration-flow_start', { integrationName });
    let attrs = {
      email: this.userManager.currentUser.email,
      locale: this.userManager.currentUser.locale,
      organization: this.organizationManager.organization,
      organizationName: this.organizationManager.organization.name,
      provider: this.provider,
    };
    if (this.integration && !this.integration.isDeleted) {
      await this.integration.regenerateLinkUrl(attrs);
    } else {
      this.integration = await this.store.createRecord('hris-integration', attrs).save();
    }
  }

  async showKomboConnectModal() {
    let integrationName = this.provider;
    try {
      await this.komboConnect.show(this.linkUrl);
      // short latency in BE updating hris_integration.status to connected
      // local storage used to display correct status
      safeLocalStorage.setItem(this._storageConnectingKey, true);
      this.segment.track('connect_integration-flow_success', { integrationName });
    } catch (error) {
      this.segment.track('connect_integration-flow_cancel', { integrationName });
      throw error;
    }
  }

  get linkUrl() {
    return this.integration.linkUrl;
  }

  async destroyRecord() {
    await this.integration.destroyRecord();
    safeLocalStorage.removeItem(this._storageConnectingKey);
  }
}

export default class HrisIntegrationModel extends Model {
  @attr provider;
  @attr status;
  @attr lastSyncedAt;
  @attr linkUrl;

  // needed for creation
  @attr organizationName;
  @attr locale;
  @attr email;

  @belongsTo('organization', { async: false, inverse: null }) organization;

  async regenerateLinkUrl(attrs) {
    this.setProperties(attrs);
    let response = await apiAction(this, {
      data: { hris_integration: this.serialize() },
      method: 'POST',
      path: 'regenerate_link_url',
      requestType: 'updateRecord',
    });
    let serializer = this.store.serializerFor('hris-integration');
    let normalizedResponse = serializer.normalize(this.constructor, response.hris_integration);
    return this.store.push(normalizedResponse);
  }
}
