import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS,
  FREQUENCY_CUSTOM_UNITS,
} from 'qonto/constants/direct-debit-subscriptions';
import DirectDebitSubscriptionValidations from 'qonto/validations/direct-debit-subscription';

export const getLabelForFrequencyCustomUnit = (value, intl) => {
  switch (value) {
    case FREQUENCY_CUSTOM_UNITS.WEEKS: {
      return intl.t('sdd-collections.recurrence.weeks');
    }
    case FREQUENCY_CUSTOM_UNITS.MONTHS: {
      return intl.t('sdd-collections.recurrence.months');
    }
    case FREQUENCY_CUSTOM_UNITS.YEARS: {
      return intl.t('sdd-collections.recurrence.years');
    }
  }
};

export const getScheduleTypeLabel = (value, intl) => {
  switch (value) {
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF: {
      return intl.t('sdd-collections.recurrence.once');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.WEEKLY: {
      return intl.t('sdd-collections.recurrence.weekly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY: {
      return intl.t('sdd-collections.recurrence.monthly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.QUARTERLY: {
      return intl.t('sdd-collections.recurrence.quarterly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.YEARLY: {
      return intl.t('sdd-collections.recurrence.yearly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM: {
      return intl.t('sdd-collections.recurrence.custom');
    }
  }
};

export const getStatusLabel = (value, intl) => {
  let statusMap = {
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE]: 'pending-mandate',
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED]: 'scheduled',
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS]: 'in-progress',
  };

  if (!statusMap[value]) {
    return '';
  }

  return intl.t(`sdd-collections.status.${statusMap[value]}`);
};

export default class DirectDebitSubscriptionModel extends Model.extend(
  DirectDebitSubscriptionValidations
) {
  @service intl;

  @tracked isCustomDuration = false;

  @attr() amount;
  @attr('string') initialCollectionDate;
  @attr('string') nextCollectionDate;
  @attr('string') endDate;
  @attr('string') reference;
  @attr('string') debitorName;
  @attr('string', { defaultValue: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY }) scheduleType;
  @attr('string') status;
  @attr('string') slug;
  @attr('number') scheduleCustomInterval;
  @attr('string', { defaultValue: FREQUENCY_CUSTOM_UNITS.MONTHS }) scheduleCustomUnit;
  @attr('number') scheduleTotalCollectionCount;
  @attr('boolean', { defaultValue: true }) notifyClient;
  @attr('string', { defaultValue: ACTIVITY_TAGS.OTHER_INCOME }) activityTag;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('direct-debit-collection-mandate', { async: false, inverse: null }) mandate;
  @belongsTo('customer', { async: false, inverse: null }) client;

  @hasMany('attachment', { async: true, inverse: null }) attachments;

  get isOneOff() {
    return this.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF;
  }

  get isRecurring() {
    return !this.isOneOff;
  }

  get isPendingMandateSignature() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE;
  }

  get isScheduled() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED;
  }

  get isInProgress() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS;
  }

  get scheduleCustomUnitLabel() {
    return getLabelForFrequencyCustomUnit(this.scheduleCustomUnit, this.intl);
  }

  get scheduleTypeLabel() {
    if (this.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM) {
      if (this.scheduleCustomInterval > 1) {
        return this.intl.t('sdd-collections.recurrence.every-custom', {
          number: this.scheduleCustomInterval,
          recurrence: getLabelForFrequencyCustomUnit(this.scheduleCustomUnit, this.intl),
        });
      } else if (this.scheduleCustomInterval === 1) {
        switch (this.scheduleCustomUnit) {
          case FREQUENCY_CUSTOM_UNITS.WEEKS: {
            return this.intl.t('sdd-collections.recurrence.weekly');
          }
          case FREQUENCY_CUSTOM_UNITS.MONTHS: {
            return this.intl.t('sdd-collections.recurrence.monthly');
          }
          case FREQUENCY_CUSTOM_UNITS.YEARS: {
            return this.intl.t('sdd-collections.recurrence.yearly');
          }
        }
      }
    }

    return getScheduleTypeLabel(this.scheduleType, this.intl);
  }

  get typeCopy() {
    if (this.isOneOff) {
      return this.intl.t('sdd-collections.types.one-off');
    } else if (this.isRecurring) {
      return this.intl.t('sdd-collections.types.recurring');
    }
  }

  get attachment() {
    return this.attachments.content[0];
  }

  get savedAttachments() {
    return this.attachments.filter(attachment => !attachment.isNew);
  }

  get attachmentsFiles() {
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  get statusDisplayInfo() {
    return {
      title: getStatusLabel(this.status, this.intl),
      icon: DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS[this.status],
      level: DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS[this.status],
    };
  }
}
