import Model, { attr, belongsTo } from '@ember-data/model';

import EInvoiceActivationValidations from 'qonto/validations/e-invoice-activation';

export default class EinvoiceActivationModel extends Model.extend(EInvoiceActivationValidations) {
  /** @type {boolean} */
  @attr eInvoicingActivated;
  /** @type {boolean} */
  @attr eInvoicingTermsAndConditionsAccepted;
  /** @type {string | null} */
  @attr fiscalCode;
  /** @type {string | null} */
  @attr provinceCode;
  /** @type {string | null} */
  @attr taxRegime;
  /** @type {string | null} */
  @attr legalTvaNumber;

  /** @type {Organization} */
  @belongsTo('organization', { async: false, inverse: null }) organization;
}
