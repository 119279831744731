import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

export default class DirectDebitHoldModel extends Model {
  @service intl;

  @attr() amount;
  @attr('string') guardingRate;
  @attr('string') slug;
  @attr('date') executionDate;
  @attr('date') releaseDate;
  @attr('boolean') isReleased;
  @attr('string') directDebitCollectionTransactionId;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;
  @belongsTo('transaction', { async: false, inverse: null }) transaction;

  get guardingPercentage() {
    if (this.guardingRate) {
      return `${Number(this.guardingRate) * 100}%`;
    }

    return null;
  }

  get holdAmount() {
    if (this.amount.value && this.guardingRate) {
      return Number(this.amount.value).toFixed(2);
    }
    return null;
  }
}
