import { attr, belongsTo, hasMany } from '@ember-data/model';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';

import { ErrorInfo } from 'qonto/utils/error-info';

import BaseReceivableInvoiceModel from './receivable-invoice/base';

export default class QuoteModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  @attr number;
  /** @type {string} YYYY-MM-DD */
  @attr('string')
  issueDate;
  /** @type {string} YYYY-MM-DD */
  @attr('string')
  expiryDate;
  /** @type {string} */
  @attr reference;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  @attr einvoicingStatus;
  /** @type {hash} */
  @attr('hash') customerSnapshot;
  /** @type {hash} */
  @attr('hash') organizationSnapshot;
  /** @type {bool} */
  @attr isCloseToExpire;
  /** @type {int} */
  @attr remainingDaysUntilExpiry;
  /** @type {string} */
  @attr invoicedAmount;
  /** @type {'fully_invoiced'|'partially_invoiced'|'no_invoices'| 'currency_mismatch'} */
  @attr invoicedStatus;

  @belongsTo('organization', { async: false, inverse: null }) organization;
  /** @type {Attachment} */
  @belongsTo('attachment', { async: false, inverse: null }) attachment;

  /** @type {Item} */
  @hasMany('receivable-invoice/item', { async: false, inverse: 'quote' }) items;

  /** @type {ReceivableInvoice} */
  @hasMany('receivable-invoice', { async: true, inverse: 'quote' }) receivableInvoices;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  @waitFor
  static async last(store) {
    let response = await store.adapterFor('quote').getLast();
    return store.push(response);
  }

  @waitFor
  static async getStats(store) {
    try {
      return await store.adapterFor('quote').getStats();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        let sentry = getOwner(store).lookup('service:sentry');
        sentry.captureException(error);
      }
      return {
        created: {
          approved: 0,
          canceled: 0,
          pendingApproval: 0,
          total: 0,
        },
      };
    }
  }

  @waitFor
  async updateQuote() {
    try {
      let payload = this.serialize({ includeId: true });
      let response = await apiAction(this, {
        method: 'PUT',
        data: payload,
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!response.data.attributes[key]) {
          response.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('quote', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateQuoteStatus(newStatus) {
    try {
      let data = {
        data: {
          type: 'quotes',
          id: this.id,
          attributes: {
            status: newStatus,
          },
          relationships: {
            organization: {
              data: {
                type: 'organizations',
                id: this.organization.id,
              },
            },
          },
        },
      };
      let response = await apiAction(this, {
        method: 'PATCH',
        path: 'status',
        data,
      });
      this.store.pushPayload('quote', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @service networkManager;
  @service intl;

  get displayedStatus() {
    let statusesMap = {
      pending_approval: this.intl.t('receivable-invoices.quotes-status.pending-approval'),
      approved: this.intl.t('receivable-invoices.quotes-status.approved'),
      canceled: this.intl.t('receivable-invoices.quotes-status.canceled'),
    };
    return statusesMap[this.status] || statusesMap['pending_approval'];
  }

  get pdfUrl() {
    return this.store.adapterFor('quote').urlForPdf(this.id);
  }

  get isCanceledOrExpired() {
    return (
      this.status === 'canceled' ||
      (this.status === 'pending_approval' && this.remainingDaysUntilExpiry === 0)
    );
  }

  @waitFor
  async setPdfPreviewIframeUrl() {
    if (!this.pdfUrl) return;

    let reader = new FileReader();
    let handler = () => {
      this.pdfPreviewIframeUrl = reader.result;
    };

    try {
      let response = await this.networkManager.rawRequest(this.pdfUrl, { method: 'GET' });
      let blob = await response.blob();

      reader.addEventListener('load', handler);
      reader.readAsDataURL(blob);
    } catch {
      this.pdfPreviewIframeUrl = null;
      reader.removeEventListener(handler);
    }
  }
}
