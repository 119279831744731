import { attr, belongsTo } from '@ember-data/model';

import Request from 'qonto/models/request';

export default class RequestVirtualCard extends Request {
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bank-account', { async: true, inverse: null }) bankAccount;
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  @belongsTo('membership', { async: true, inverse: null }) approver;
  @belongsTo('membership', { async: true, inverse: null }) holder;
  @belongsTo('card', { async: true, inverse: null }) card;

  @attr('number') paymentMonthlyLimit;
  @attr status;
  @attr currency;
  @attr processedAt;
  @attr createdAt;
  @attr('string', { defaultValue: 'virtual_card' }) requestType;
}
