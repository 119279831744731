import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import Modifier from 'ember-modifier';

function cleanup(instance) {
  instance.element.removeEventListener('click', instance.trackEvent);
  instance.element = null;
  instance.eventName = null;
  instance.options = null;
}

/**
 * Useful to fire a track event from a link under a <a href…></a> nested in a translation.
 * To use it:
 *
 * <div {{track-on-click "event">{{t "translation.with.link"}}</div>
 */
export default class OnInnerClickModifier extends Modifier {
  @service segment;

  eventName;
  options;

  @action
  trackEvent(event) {
    if (macroCondition(isTesting())) {
      if (this.element.getAttribute('target') === '_blank') {
        event.preventDefault();
      }
    }
    this.segment.track(this.eventName, this.options);
  }

  modify(element, [eventName, options]) {
    // the element that will actually have the listener installed on it
    this.element = element.querySelector('a, button') ?? element;

    // arguments for segment tracking
    this.eventName = eventName;
    this.options = options;

    this.element.addEventListener('click', this.trackEvent);
    registerDestructor(this, cleanup);
  }
}
