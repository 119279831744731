// @ts-nocheck
import Model, { attr, belongsTo } from '@ember-data/model';

export default class CardAcquirerPaymentModel extends Model {
  @attr() amount;
  @attr('string') reference;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('membership', { async: true, inverse: null }) membership;
  @belongsTo('card-acquirer-payout', { async: true, inverse: null }) payout;

  @attr('string') status;
  @attr('string') paymentMethod;
  @attr('string') cardLast4;
  @attr('date') processingAt;
  @attr('date') completedAt;
  @attr('date') declinedAt;
}
