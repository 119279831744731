import Model, { attr, belongsTo } from '@ember-data/model';

import { DEFAULT_FIELD, DEFAULT_OPERATOR } from 'qonto/constants/reminders-configuration';
import RemindersConfigurationRuleValidations from 'qonto/validations/reminders-configuration/rule';

export default class RemindersConfigurationRuleModel extends Model.extend(
  RemindersConfigurationRuleValidations
) {
  @attr('string', { defaultValue: DEFAULT_OPERATOR }) operator;
  @attr('string', { defaultValue: DEFAULT_FIELD }) field;
  @attr('number') offsetDays;
  @attr('string') emailTitle;
  @attr('string') emailBody;

  @belongsTo('reminders-configuration', { async: false, inverse: 'rules' }) remindersConfiguration;
}
