import Model, { attr, belongsTo } from '@ember-data/model';

export default class InvoiceModel extends Model {
  @attr() amount;
  @attr() totalAmount;
  @attr() vatAmount;
  @attr('string') currency;
  @attr('string') pdfUrl;
  @attr('string') slug;
  @attr('date') fromDate;
  @attr('date') toDate;

  @belongsTo('organization', { async: true, inverse: null }) organization;
}
