import Model, { attr, belongsTo } from '@ember-data/model';

export default class GmiSolutionInstanceModel extends Model {
  @attr('string') userId;
  @attr('string') status;
  @attr('string') gmiStatus;
  @attr('string') troubleshootingUrl;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  @belongsTo('organization', { async: false, inverse: null }) organization;
  @belongsTo('solution-instance', {
    async: true,
    inverse: null,
    key: 'solution_instance_id',
  })
  solutionInstance;
}
