import Model, { attr } from '@ember-data/model';

import dayjs from 'dayjs';

import {
  BANK_CONNECTION_ACTIONABLE_STATUSES,
  BANK_CONNECTION_FAILURE_STATUSES,
  CONNECTION_EXPIRATION_STATUS,
  CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS,
} from 'qonto/constants/connect';

export default class BankConnectionModel extends Model {
  /** @type {string} */
  @attr name;

  /** @type {string|null} */
  @attr bic;

  /** @type {{small: string, medium: string}} */
  @attr logo;

  /**
   * @typedef {Object} Account
   * @property {string} name
   */
  /** @type {{accounts: Account[]}} */
  @attr accounts;

  /** @type {Date} */
  @attr('date') grantedAt;

  /** @type {Date|null} */
  @attr('date') expiresAt;

  /** @type {Date|null} */
  @attr('date') lastSuccessfulSyncAt;

  /** @type {BANK_CONNECTION_STATUS | null} */
  @attr status;

  /** @type {BANK_CONNECTION_PROVIDER | null} */
  @attr providerId;

  get memberName() {
    // Bank connections are not tied to a specific member, so we return a placeholder
    return '-';
  }

  get isFailedConnection() {
    if (!this.status) return false;

    return BANK_CONNECTION_FAILURE_STATUSES.includes(this.status);
  }

  get isActionableConnection() {
    if (!this.status) return false;

    return BANK_CONNECTION_ACTIONABLE_STATUSES.includes(this.status);
  }

  get isExpiredConnection() {
    if (!this.expiresAt) return false;

    return dayjs(this.expiresAt).isBefore(dayjs());
  }

  get isExpiringConnection() {
    if (!this.expiresAt) return false;

    let thresholdDate = dayjs().add(CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS, 'day');

    return dayjs(this.expiresAt).isBefore(thresholdDate);
  }

  get connectionStatus() {
    if (this.status) {
      if (this.isFailedConnection) return this.status;

      if (!this.expiresAt) return CONNECTION_EXPIRATION_STATUS.UNKNOWN;
      if (this.isExpiredConnection) return CONNECTION_EXPIRATION_STATUS.EXPIRED;
      if (this.isExpiringConnection) return CONNECTION_EXPIRATION_STATUS.EXPIRING;

      return this.status;
    }

    if (!this.expiresAt) return CONNECTION_EXPIRATION_STATUS.UNKNOWN;
    if (this.isExpiredConnection) return CONNECTION_EXPIRATION_STATUS.EXPIRED;
    if (this.isExpiringConnection) return CONNECTION_EXPIRATION_STATUS.EXPIRING;

    return CONNECTION_EXPIRATION_STATUS.ACTIVE;
  }
}
