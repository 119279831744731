import Model, { attr } from '@ember-data/model';

export default class RemuneratedAccountModel extends Model {
  /** @type {string} */
  @attr accountId;
  /** @type {number} */
  @attr currentMaturity;
  /** @type {number} */
  @attr maxMaturity;
  /** @type {Date} */
  @attr('date') startDate;
  /** @type {Date} */
  @attr('date') endDate;
  /** @type {boolean} */
  @attr isTaxApplicable;
  /** @type object {value: string, currency: string} */
  @attr maxMaturityEarnings;
  /** @type {array} */
  @attr interestRates;
}
