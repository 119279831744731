import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import GeneratedLogoValidations from 'qonto/validations/generated-logo';

export default class GeneratedLogoModel extends Model.extend(GeneratedLogoValidations) {
  @attr('string') locale;
  @attr('string') status;
  @attr('string') userInput;
  @attr('string', { defaultValue: 'any' }) color;
  @attr('string', { defaultValue: 'any' }) style;
  @attr('string') url;

  @belongsTo('organization', { async: false, inverse: null }) organization;

  @waitFor
  async uploadLogo() {
    return await apiAction(this, { method: 'PUT', path: 'upload' });
  }
}
