import { attr, belongsTo, hasMany } from '@ember-data/model';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import { variation } from 'ember-launch-darkly';

import { STATUS } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

import BaseReceivableInvoiceModel from './receivable-invoice/invoice-base';

export default class ReceivableInvoiceModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  @attr number;
  /** @type {string} */
  @attr('string') issueDate; // YYYY-MM-DD
  /** @type {string} */
  @attr('string') dueDate; // YYYY-MM-DD
  /** @type {string} YYYY-MM-DD */
  @attr paidAt;
  /** @type {boolean} */
  @attr overdue;
  /** @type {string} */
  @attr bic;
  /** @type {string} */
  @attr iban;
  /** @type {string} */
  @attr reference;
  /** @type {string} */
  @attr subtotal;
  /** @type {string} */
  @attr vatAmount;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  @attr einvoicingStatus;
  /** @type {hash} */
  @attr('hash') customerSnapshot;
  /** @type {string} */
  @attr importAnalyzedAt;
  /** @type {string} */
  @attr header;
  /** @type {string} */
  @attr footer;
  /** @type {string} YYYY-MM-DD */
  @attr performanceDate;
  /** @type {hash} */
  @attr('hash') settings;
  /** @type {hash} */
  @attr('hash') organizationSnapshot;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) imported;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) hasDuplicates;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) directDebitEnabled;
  /** @type {string} */
  @attr fileName;
  /** @type {string} YYYY-MM-DD */
  @attr nextReminderDate;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) isEinvoice;
  /** @type Array<{status_code: number, reason: string, reason_message: string, timestamp: string}> | null **/
  @attr({ defaultValue: null }) einvoicingLifecycleEvents;

  /** @type {Attachment} */
  @belongsTo('attachment', { async: true, inverse: null }) attachment;

  /** @type {Quote} */
  @belongsTo('quote', { async: true, inverse: 'receivableInvoices' }) quote;

  /** @type {Item} */
  @hasMany('receivable-invoice/item', { async: false, inverse: 'receivableInvoice' }) items;

  /** @type {ReceivableCreditNote} */
  @hasMany('receivable-credit-note', { async: true, inverse: 'receivableInvoice' })
  receivableCreditNotes;

  /** @type {InvoiceSubscription} */
  @belongsTo('invoice-subscription', { async: true, inverse: null }) invoiceSubscription;

  /** @type {directDebitSubscription} */
  @belongsTo('direct-debit-subscription', { async: true, inverse: null }) directDebitSubscription;

  @service intl;
  @service networkManager;
  @service sentry;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;
  @tracked fileType = null;

  @waitFor
  async validate() {
    try {
      let data = this.serialize();
      return await apiAction(this, {
        method: 'POST',
        path: 'validate',
        data,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async saveAsDraft() {
    this.status = STATUS.DRAFT;
    try {
      return await super.save(...arguments);
    } catch (error) {
      this.status = STATUS.UNPAID;
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  @waitFor
  async updateDraft() {
    try {
      let payload = this.serialize({ includeId: true });
      let response = await apiAction(this, {
        method: 'PUT',
        data: payload,
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!response.data.attributes[key]) {
          response.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateImportedInvoiceAmount(updatedAmounts) {
    try {
      let payload = this.serialize({ includeId: true });

      Object.keys(updatedAmounts).forEach(key => {
        payload.data.attributes[key] = {
          ...payload.data.attributes[key],
          value: updatedAmounts[key],
        };
      });

      await apiAction(this, {
        method: 'PUT',
        data: payload,
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!payload.data.attributes[key]) {
          payload.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', {
        data: payload.data,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateImportedInvoice() {
    try {
      let payload = this.serialize({ includeId: true });

      await apiAction(this, {
        method: 'PUT',
        data: payload,
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!payload.data.attributes[key]) {
          payload.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', {
        data: payload.data,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async finalizeDraft() {
    try {
      let response = await apiAction(this, {
        method: 'POST',
        path: 'finalize',
        data: this.serialize({ includeId: true }),
      });
      this.store.pushPayload('receivable-invoice', response);
      // We need to unload the record in order to force getting the correct new number that is determined by BE
      next(() => this.unloadRecord());
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAsPaid(paidAt) {
    try {
      let data = {
        data: {
          type: 'receivable_invoices',
          attributes: { paid_at: paidAt },
        },
      };
      let response = await apiAction(this, {
        method: 'POST',
        path: 'mark_as_paid',
        data,
      });
      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAsUnpaid() {
    try {
      this.rollbackAttributes();
      let response = await apiAction(this, {
        method: 'POST',
        path: 'unmark_as_paid',
      });
      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  static async getStats(store) {
    try {
      return await store.adapterFor('receivable-invoice').getStats();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        let sentry = getOwner(store).lookup('service:sentry');
        sentry.captureException(error);
      }
      return {
        created: {
          canceled: 0,
          draft: 0,
          paid: 0,
          total: 0,
          unpaid: 0,
        },
      };
    }
  }

  @waitFor
  static async last(store) {
    let response = await store.adapterFor('receivable-invoice').getLast();
    return store.push(response);
  }

  get displayedStatus() {
    let statusesMap = {
      canceled: this.intl.t('receivable-invoices.status.canceled'),
      paid: this.intl.t('receivable-invoices.status.paid'),
      unpaid: this.intl.t('receivable-invoices.status.unpaid'),
      draft: this.intl.t('receivable-invoices.status.draft'),
    };
    return statusesMap[this.status];
  }

  get displayEachVatSubtotals() {
    return (
      variation('improvement--boolean-invoicing-subtotal-vat-rate') && this.vatRates.length > 1
    );
  }

  // save in an array all the unique different vat rates used in the document
  get vatRates() {
    return [...new Set(this.items.map(item => item?.vatRate))].sort();
  }

  // save in an array object for each unique different vat rates with their groupped calculated totals of vat amount and of amount excluding vat
  get vatSubtotals() {
    let vatSubtotals = [];
    if (this.displayEachVatSubtotals) {
      for (let index = 0; index < this.vatRates.length; index++) {
        let rate = this.vatRates[index];

        let totalExcludingVat = 0;
        let vatTotal = 0;

        for (let item of this.items) {
          if (rate === item?.vatRate) {
            totalExcludingVat += parseFloat(item.discountedTotalExcludingVat);
            vatTotal += parseFloat(item.totalVat);
          }
        }

        vatSubtotals.push({
          rate,
          vatTotal,
          totalExcludingVat,
        });
      }
    }

    return vatSubtotals;
  }

  get pdfUrl() {
    return this.store.adapterFor('receivable-invoice').urlForPdf(this.id);
  }

  @waitFor
  async setPdfPreviewIframeUrl() {
    if (!this.pdfUrl) return;

    let reader = new FileReader();
    let handler = () => {
      this.pdfPreviewIframeUrl = reader.result;
    };

    try {
      let response = await this.networkManager.rawRequest(this.pdfUrl, { method: 'GET' });
      let blob = await response.blob();

      this.fileType = blob.type;

      reader.addEventListener('load', handler);
      reader.readAsDataURL(blob);
    } catch {
      this.pdfPreviewIframeUrl = null;
      this.fileType = null;
      reader.removeEventListener(handler);
    }
  }
}
