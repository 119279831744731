import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { camelize } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { EXERCISE_KINDS } from 'qonto/constants/budget';
import BudgetValidations from 'qonto/validations/budget';

export default class BudgetModel extends Model.extend(BudgetValidations) {
  @attr name;
  @attr restricted;
  @attr archived;
  @attr exerciseKind;
  @attr supervisorIds;

  @belongsTo('assignable', { async: false, inverse: null, polymorphic: true }) assignedTo;
  @hasMany('exercise', { async: false, inverse: 'budget' }) exercises;
  @belongsTo('period', { async: false, inverse: null }) activePeriod;

  get isGlobal() {
    return (
      this.exerciseKind === EXERCISE_KINDS.YEARLY || this.exerciseKind === EXERCISE_KINDS.GLOBAL
    );
  }

  @waitFor
  async save() {
    try {
      return await super.save();
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        error.errors.forEach(({ detail: { code, source } }) => {
          this.addError(source.pointer, code);
        });
        this._removeIncorrectlyAssignedErrors();
      }
      throw error;
    }
  }

  // https://github.com/emberjs/data/blob/3aa0fbddb7a650361bed44350c3b51669ec9065e/packages/store/addon/-private/store-service.ts#L2728
  // Doesn't work well for our nested non jsonapi errors pointers
  // So we remove them manually
  // Would be good to fix this at the store / adapter / serializer level
  _removeIncorrectlyAssignedErrors() {
    let errorsToRemove = [];
    this.errors.forEach(({ attribute }) => {
      if (!isNaN(parseInt(attribute, 10))) {
        errorsToRemove.push(attribute);
      }
    });
    errorsToRemove.forEach(attribute => this.errors.remove(attribute));
  }

  addError(pointer, code) {
    let [, , attrOrRel, index, ...subPointer] = pointer.split('/');
    if (attrOrRel === 'exercises') {
      let exercisePointer =
        subPointer.length === 0 ? '/exercise' : `/exercise/${subPointer.join('/')}`;
      this.exercises.at(index).addError(exercisePointer, code);
    } else {
      this.errors.add(camelize(attrOrRel || ''), code);
    }
  }

  get currentExercise() {
    return this.exercises.find(({ startDate, endDate }) => {
      return dayjs().isBetween(startDate, endDate, 'day', '[]');
    });
  }

  get nextExercise() {
    return [...this.exercises]
      .sort(({ startDate: a }, { startDate: b }) => dayjs(a).diff(b))
      .find(({ startDate }) => dayjs(startDate).isAfter(dayjs()));
  }

  get nextExercises() {
    return [...this.exercises]
      .sort(({ startDate: a }, { startDate: b }) => dayjs(a).diff(b))
      .filter(({ startDate }) => dayjs(startDate).isAfter(dayjs()));
  }

  get exercisesEndingInTheFuture() {
    return [...this.exercises]
      .sort(({ endDate: a }, { endDate: b }) => dayjs(a).diff(b))
      .filter(({ endDate }) => dayjs(endDate).isAfter(dayjs()));
  }

  @waitFor
  async archive() {
    let response = await apiAction(this, {
      method: 'POST',
      path: 'archive',
    });
    let serializer = this.store.serializerFor('budget');
    let normalizedResponse = serializer.normalize(this.constructor, response.budget);
    return this.store.push(normalizedResponse);
  }
}
