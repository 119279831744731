import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';

import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';

export default class SolutionInstanceModel extends Model {
  @attr solutionId;
  @attr status;
  @attr('date') createdAt;

  // Enrichment attributes
  @attr('string') name;
  @attr('string') logoUrl;

  @belongsTo('membership', { async: true, inverse: null }) membership;
  @belongsTo('organization', { async: true, inverse: null }) organization;

  @tracked wizardUrl;
  @tracked wizardIsPopup;

  get isEnabled() {
    return this.status === SOLUTION_INSTANCE_STATUS.ENABLED;
  }

  enrichWith({ name, logoUrl }) {
    this.set('name', name);
    this.set('logoUrl', logoUrl);
  }

  @waitFor
  async getWizardURL() {
    let response = await apiAction(this, { method: 'POST', path: 'wizard' });
    this.wizardUrl = response.wizard.url;
    this.wizardIsPopup = response.wizard.is_popup;
  }

  @waitFor
  async enable() {
    let response = await apiAction(this, { method: 'PATCH', path: 'enable' });
    this.status = response.solution_instance?.status;
  }
}
