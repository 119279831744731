import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { apiBaseURL, savingsAccountNamespace } from 'qonto/constants/hosts';
import { STATUS } from 'qonto/constants/savings-account';
import { differenceInCalendar } from 'qonto/utils/date';

export default class SavingsAccountModel extends Model {
  @service organizationManager;

  /** @type {number} */
  @attr amount;
  /** @type {string} */
  @attr amountCurrency;
  /** @type {Date} */
  @attr('date') createdAt;
  /** @type {string} YYYY-MM-DD */
  @attr closureEffectiveOn;
  /** @type {number|null} */
  @attr closureExpectedGain;
  /** @type {number|null} */
  @attr closureInterestRate;
  /** @type {number} */
  @attr durationInMonth;
  /** @type {number} */
  @attr expectedGain;
  /** @type {number} */
  @attr interestRate;
  /** @type {string} YYYY-MM-DD */
  @attr maturityOn;
  /** @type {string} */
  @attr name;
  /** @type {string} YYYY-MM-DD */
  @attr openedOn;
  /** @type {string} */
  @attr sequentialId;
  /** @type {string|null} */
  @attr statementPdfUrl;
  /** @type {'processing'|'active'|'closing'|'closed'} */
  @attr status;
  /** @type {boolean} */
  @attr isCloseable;
  /** @type {string} */
  @attr procedureId;

  @waitFor
  async initiateClosure() {
    let data = { organization_id: this.organizationManager.organization.id };
    let response = await apiAction(this, { method: 'POST', path: 'closure_contract', data });
    return this.store.pushPayload(response);
  }

  @waitFor
  async signClosure() {
    let data = { organization_id: this.organizationManager.organization.id };
    return await apiAction(this, { method: 'POST', path: 'closure_contract_sign', data });
  }

  get closedOn() {
    return this.status === STATUS.CLOSING
      ? this.closureEffectiveOn
      : this.closureEffectiveOn || this.maturityOn;
  }

  get sortDate() {
    switch (this.status) {
      case STATUS.PROCESSING:
        return -this.createdAt.getTime();
      case STATUS.ACTIVE:
        return this.openedOn;
      case STATUS.CLOSING:
        return this.closureEffectiveOn;
      default:
        return this.closureEffectiveOn || this.maturityOn;
    }
  }

  get sortStatusOrder() {
    return [STATUS.PROCESSING, STATUS.ACTIVE, STATUS.CLOSING, STATUS.CLOSED].indexOf(this.status);
  }

  get progressInMonths() {
    if (this.openedOn) {
      return differenceInCalendar(new Date(), this.openedOn, 'month');
    }
    return 0;
  }

  get progressInDays() {
    if (this.openedOn) {
      return dayjs().diff(this.openedOn, 'day');
    }
    return 0;
  }

  get durationInDays() {
    if (this.openedOn) {
      return differenceInCalendar(
        dayjs(this.openedOn).add(this.durationInMonth, 'month'),
        this.openedOn,
        'day'
      );
    }
    return Number.POSITIVE_INFINITY;
  }

  get closureDurationInMonths() {
    if (this.closureEffectiveOn) {
      return differenceInCalendar(this.closureEffectiveOn, this.openedOn, 'month');
    }
  }

  get closureExpectedAmount() {
    return parseFloat((Number(this.amount) + Number(this.closureExpectedGain)).toFixed(2));
  }

  get isProcessing() {
    return this.status === STATUS.PROCESSING;
  }

  get isActive() {
    return this.status === STATUS.ACTIVE;
  }

  get isClosing() {
    return this.status === STATUS.CLOSING;
  }

  get isClosedNaturally() {
    return this.status === STATUS.CLOSED && !this.closureEffectiveOn;
  }

  get isClosedEarly() {
    return this.status === STATUS.CLOSED && this.closureEffectiveOn;
  }

  get contractUrl() {
    if (!this.procedureId) return;
    return `${apiBaseURL}/${savingsAccountNamespace}/savings_accounts/contract?organization_id=${this.organizationManager.organization.id}&procedure_id=${this.procedureId}`;
  }

  get closureContractUrl() {
    if (!(this.isClosedEarly || this.isClosing)) return;
    return `${apiBaseURL}/${savingsAccountNamespace}/savings_accounts/${this.id}/closure_contract?organization_id=${this.organizationManager.organization.id}`;
  }
}
