import Model, { attr } from '@ember-data/model';

export default class F24RegioniTaxItemModel extends Model {
  @attr taxCode;
  @attr regionCode;
  @attr referenceMonth;
  @attr referenceYear;
  @attr('number') taxAmount;
  @attr('number') compensationAmount;
}
