import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isEmpty } from '@ember/utils';

import PeriodValidations from 'qonto/validations/period';

export default class PeriodModel extends Model.extend(PeriodValidations) {
  /** @type {string} YYYY-MM-DD */
  @attr startDate;
  /** @type {string} YYYY-MM-DD */
  @attr endDate;
  @attr amountBudgeted;
  @attr amountSettled;

  @service intl;

  @belongsTo('exercise', { async: false, inverse: 'periods' }) exercise;
  @belongsTo('periodComputedAmount', { async: false, inverse: 'period' }) computedAmounts;

  get name() {
    if (this.exercise.isGlobal) {
      return capitalize(this.intl.formatDate(this.startDate, { year: 'numeric' }));
    } else {
      return capitalize(this.intl.formatDate(this.startDate, { month: 'long' }));
    }
  }

  get amountBudgetedValue() {
    return this.amountBudgeted?.value;
  }

  get amountComputedValue() {
    return this.computedAmounts?.amountSettled?.value;
  }

  get amountRemainingValue() {
    if (this.amountBudgetedValue && this.amountComputedValue) {
      return parseFloat(this.amountBudgetedValue) - parseFloat(this.amountComputedValue);
    }

    if (this.amountBudgetedValue && this.amountSettledValue) {
      return parseFloat(this.amountBudgetedValue) - parseFloat(this.amountSettledValue);
    }

    return null;
  }

  get amountSettledValue() {
    return this.amountSettled?.value;
  }

  set amountBudgetedValue(value) {
    if (isNaN(value) || isEmpty(value) || parseFloat(value) === 0) {
      this.amountBudgeted = null;
    } else {
      this.amountBudgeted = { currency: 'EUR', ...this.amountBudgeted, value };
    }
  }
}
