import Model, { belongsTo, hasMany } from '@ember-data/model';

export default class F24TaxInformationModel extends Model {
  @belongsTo('f24/erario', { async: false, inverse: null }) erario;
  @belongsTo('f24/imu', { async: false, inverse: null }) imu;
  @belongsTo('f24/others-tax', { async: false, inverse: null }) others;
  @hasMany('f24/inps-tax-item', { async: false, inverse: null }) inpsTaxList;
  @hasMany('f24/inail-tax-item', { async: false, inverse: null }) inailTaxList;
  @hasMany('f24/regioni-tax-item', { async: false, inverse: null }) regioniTaxList;
}
