import Model, { attr, belongsTo } from '@ember-data/model';

import { equal } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';

export default class FeeModel extends Model {
  @attr('number') amount;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string') feeType;
  @attr('string') reference;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  @equal('feeType', 'physical_card') isPhysicalCard;
  @equal('feeType', 'virtual_card') isVirtualCard;
  @equal('feeType', 'sepa_inbound_transfer') isSepaInboundTransfer;
  @equal('feeType', 'sepa_outbound_transfer') isSepaOutboundTransfer;
  @equal('feeType', 'atm') isAtm;
  @equal('feeType', 'fx_card') isFxCard;
  @equal('feeType', 'fx_inbound_transfer') isFxInboundTransfer;
  @equal('feeType', 'fx_outbound_transfer') isFxOutboundTransfer;
  @equal('feeType', 'subscription') isSubscription;
  @equal('feeType', 'physical_card_delivery') isPhysicalCardDelivery;
  @equal('feeType', 'additional_user') isAdditionalUser;

  // Temporary until deletion
  isFee = true;
}
