import Model, { attr, belongsTo } from '@ember-data/model';

// available docTypes = [
//   'kbis',
//   'bylaw',
//   'poa',
//   'poi',
//   'contract',
//   'admin_power',
//   'employee_power',
//   'register_power'
// ]

export default class Document extends Model {
  @attr('string') subjectId;
  @attr('string') subjectType;
  @attr('string') slug;
  @attr('string') docType;
  @attr('string') docSubtype;
  @attr('string') status;
  @attr('files') files;

  @belongsTo('invite', { async: true, inverse: 'documents' }) invite;
  @belongsTo('membership', { async: true, inverse: 'documents' }) membership;
  @belongsTo('organization', { async: true, inverse: 'documents' }) organization;
  @belongsTo('stakeholder', { async: true, inverse: 'documents' }) stakeholder;

  get file() {
    return this.files?.[0];
  }

  set(key, file) {
    if (Array.isArray(this.files)) {
      this.set('files.0', file);
    } else {
      this.set('files', [file]);
    }
    return file;
  }
}
