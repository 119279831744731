import { modifier } from 'ember-modifier';

export default modifier((element, [showBalance, ...selectors]) => {
  selectors.forEach(selector => {
    let selectedElement = element.querySelector(selector);
    if (!selectedElement) return;

    if (showBalance) {
      selectedElement.classList.remove('hidden');
    } else {
      selectedElement.classList.add('hidden');
    }
  });
});
