import Model, { attr, belongsTo } from '@ember-data/model';

export default class SearchPresetModel extends Model {
  @attr name;
  @attr position;
  @attr matchesCount;
  @attr query;
  @attr type;
  @attr createdAt;

  @belongsTo('organization', { async: true, inverse: null }) organization;
}
