import Model, { attr } from '@ember-data/model';

export default class F24ImuTaxItemModel extends Model {
  @attr taxCode;
  @attr receiverCode;
  @attr('boolean') isAmendment;
  @attr('boolean') isRealEstateVariation;
  @attr('boolean') isDeposit;
  @attr('boolean') isFinalPayment;
  @attr('number') realEstateCount;
  @attr referenceMonth;
  @attr referenceYear;
  @attr('number') taxAmount;
  @attr('number') compensationAmount;
}
