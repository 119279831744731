import Model, { attr, belongsTo } from '@ember-data/model';

export default class UserActionV2Model extends Model {
  // we need to store an id that is unique for a user_action with membership id. However, the API just provides the user_action id, so we override it in the serializer, and create a copy to keep the PATCH working
  @attr originalId;

  @attr key;
  @attr status;
  @attr type;
  @attr descriptionText;
  @attr ctaText;
  @attr titleText;
  @attr illustrationUrl;
  @attr('boolean') isBadgeRequired;
  @attr('boolean') isPrimaryCard;
  @attr hiddenBy;
  @attr blockedBy;

  @attr('hash') deeplink;

  @belongsTo('membership', { async: false, inverse: null }) membership;
}
