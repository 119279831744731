import Model, { attr, belongsTo } from '@ember-data/model';

import LegalEntityValidations from 'qonto/validations/legal-entity';

export default class LegalEntityModel extends Model.extend(LegalEntityValidations) {
  @attr legalName;
  @attr legalNumber;
  @attr capitalAmount;

  @belongsTo('organization', { async: false, inverse: null }) organization;
  @belongsTo('stakeholder', { async: false, inverse: null }) stakeholder;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
}
