import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

import { equal } from 'macro-decorators';

import { INSURANCE_CONTRACT_STATUS, INSURANCE_PRODUCT_TYPES } from 'qonto/constants/insurance-hub';

export default class InsuranceContractModel extends Model {
  @service intl;

  @attr('string') contractId;
  @attr('string') name;
  @attr('string') paymentFrequency;
  @attr('string') providerSlug;
  @attr('string') serviceUrl;
  @attr('string') status;
  @attr('string') type;
  @attr('string') troubleshootingUrl;
  @attr('date') expirationDate;
  @attr('date') startDate;
  @attr('date') renewalDate;
  @attr price;
  @attr documents;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('membership', { async: true, inverse: null }) membership;

  @equal('status', INSURANCE_CONTRACT_STATUS.EXPIRED) isExpired;

  get policyProductType() {
    let productTypes = {
      // Stello (FR)
      [INSURANCE_PRODUCT_TYPES.BUSINESS_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.business_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.COLLECTIVE_HEALTCARE]: this.intl.t(
        'insurance-hub.policies.product-type.collective_healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.CYBER]: this.intl.t('insurance-hub.policies.product-type.cyber'),
      [INSURANCE_PRODUCT_TYPES.DECENNIAL]: this.intl.t(
        'insurance-hub.policies.product-type.decennial'
      ),
      [INSURANCE_PRODUCT_TYPES.HEALTHCARE]: this.intl.t(
        'insurance-hub.policies.product-type.healthcare'
      ),
      [INSURANCE_PRODUCT_TYPES.IT_HARDWARE_DAMAGE]: this.intl.t(
        'insurance-hub.policies.product-type.it_hardware_damage'
      ),
      [INSURANCE_PRODUCT_TYPES.KEY_PERSON]: this.intl.t(
        'insurance-hub.policies.product-type.key_person'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_PROTECTION]: this.intl.t(
        'insurance-hub.policies.product-type.legal_protection'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_SERVICE]: this.intl.t(
        'insurance-hub.policies.product-type.legal_service'
      ),
      [INSURANCE_PRODUCT_TYPES.MOTOR_FLEET]: this.intl.t(
        'insurance-hub.policies.product-type.motor_fleet'
      ),
      [INSURANCE_PRODUCT_TYPES.MULTI_RISK]: this.intl.t(
        'insurance-hub.policies.product-type.multi_risk'
      ),
      [INSURANCE_PRODUCT_TYPES.OFFICE]: this.intl.t('insurance-hub.policies.product-type.office'),
      [INSURANCE_PRODUCT_TYPES.PUBLIC_LIABILITY]: this.intl.t(
        'insurance-hub.policies.product-type.public_liability'
      ),
      [INSURANCE_PRODUCT_TYPES.REVENUE_LOSS]: this.intl.t(
        'insurance-hub.policies.product-type.revenue_loss'
      ),

      // Surein (DE)
      [INSURANCE_PRODUCT_TYPES.BUSINESS_LIABILITY_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.business_liability_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.CYBER_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.cyber_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.FINANCIAL_LOSS_PROTECTION_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.financial_loss_protection_surein'
      ),
      [INSURANCE_PRODUCT_TYPES.LEGAL_PROTECTION_SUREIN]: this.intl.t(
        'insurance-hub.policies.product-type.legal_protection_surein'
      ),
    };

    return productTypes[this.type] || this.name;
  }
}
