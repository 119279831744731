import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import CURRENCIES from 'qonto/constants/currencies';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import {
  COMPLETED_STATUS,
  ERROR_ALREADY_CANCELED,
  ERROR_CANNOT_CANCEL,
  OPERATION_TYPES,
} from 'qonto/constants/transfers';
import Subject from 'qonto/models/subject';
import { getCurrentParisDateString } from 'qonto/utils/date';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import TransferValidations from 'qonto/validations/transfer';

export default class TransferModel extends Subject.extend(TransferValidations) {
  @service intl; //this service is used in validator
  @service abilities;
  @service networkManager;
  @service organizationManager;
  @service store;

  @attr('boolean') instant; // true` if the transfer is SEPA instant, `false` otherwise
  @attr('boolean') notifyByEmail;
  @attr('boolean') editable;
  @attr('boolean', { defaultValue: false }) fx;
  @attr('string') accountNumber;
  @attr('string') activityTag;
  @attr('string') declinedReason;
  @attr('string') fxSettlementStatus;
  @attr('string') fxPaymentPurpose;
  @attr('string') note;
  @attr('string') sequentialId;
  @attr('string') localAmountCurrency;
  @attr('string') proofPdfUrl;
  @attr('string') slug;
  @attr('string') routingNumber;
  @attr('string', { defaultValue: '' }) reference;
  @attr('string', { defaultValue: 'pending' }) status;
  @attr('string', { defaultValue: CURRENCIES.default }) amountCurrency;
  @attr('string', { defaultValue: 'scheduled' }) operationType;
  @attr('string', { defaultValue: '' }) sortCode;
  @attr('string', { defaultValue: 'iban' }) accountType;
  @attr('string', { defaultValue: 'FR' }) country;
  @attr('number') vatAmount;
  @attr('number') vatRate;
  @attr('number') amount;
  @attr('number') localAmount;
  @attr('date') createdAt;
  @attr bic;
  @attr cancelable; // true` if the transfer can still be cancelled, `false` otherwise
  @attr creditBankAccountId;
  @attr email;
  @attr('number') fxRate;
  @attr name;
  @attr({ defaultValue: '' }) iban;
  @attr('string', { defaultValue: getCurrentParisDateString }) scheduledDate;
  @attr('date') nextRecursionDate;
  @attr('date') lastRecursionDate;
  @attr('number') standingOn;
  @attr('string') standingEndingDate; // YYYY-MM-DD
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  enrichmentData;
  @attr({ defaultValue: null }) counterparty;
  @attr('number', { defaultValue: 3 }) financingInstallments;

  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('transaction', { async: true, inverse: null }) transaction;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('membership', { async: true, inverse: null }) initiator;

  @hasMany('attachment', { async: true, inverse: null }) attachments;
  @attr() attachmentIds;

  @hasMany('label', { async: false, inverse: null }) labels;

  idempotencyKey = null;
  originalBeneficiaryEmail = null;

  @tracked minFxAmount = null;
  @tracked paymentPurposeType = null;
  // accessing `ManyArray` using `content` property
  @reads('attachments.content.0') attachment;

  get savedAttachments() {
    return this.attachments.filter(attachment => !attachment.isNew);
  }

  get attachmentCount() {
    return this.savedAttachments.length;
  }

  get isIBANAccount() {
    return this.accountType === ACCOUNT_TYPES.IBAN;
  }

  get isCanceled() {
    return this.status === 'canceled';
  }

  get isCompleted() {
    return this.status === 'completed';
  }

  get isDeclined() {
    return this.status === 'declined';
  }

  get isPending() {
    return this.status === 'pending';
  }

  get isProcessing() {
    return this.status === 'processing';
  }

  get isPendingReview() {
    return this.status === 'pending_review';
  }

  get isPendingSeizure() {
    return this.status === 'pending_seizure';
  }

  get isValidated() {
    return this.status === 'validated';
  }

  get isStandingProcessing() {
    return this.status === 'standing_processing';
  }

  get isSeizure() {
    return this.operationType === 'seizure';
  }

  get isScheduled() {
    return this.operationType === 'scheduled';
  }

  get isAccountCanceled() {
    return this.status === 'account_canceled';
  }

  get isBeneficiaryAccountCanceled() {
    return this.status === 'beneficiary_account_canceled';
  }

  get isKYBOnHold() {
    return this.status === 'kyb_onhold';
  }

  get isScheduledLater() {
    return this.operationType === 'scheduled_later';
  }

  get isFxScheduled() {
    return this.operationType === 'fx_scheduled';
  }

  get isStandingMonthly() {
    return this.operationType === SCHEDULE_OPERATION_TYPES.MONTHLY;
  }

  get isStandingWeekly() {
    return this.operationType === SCHEDULE_OPERATION_TYPES.WEEKLY;
  }

  get isStandingQuarterly() {
    return this.operationType === SCHEDULE_OPERATION_TYPES.QUARTERLY;
  }

  get isStandingHalfYearly() {
    return this.operationType === SCHEDULE_OPERATION_TYPES.HALF_YEARLY;
  }

  get isStandingYearly() {
    return this.operationType === SCHEDULE_OPERATION_TYPES.YEARLY;
  }

  get isStanding() {
    return (
      this.isStandingWeekly ||
      this.isStandingMonthly ||
      this.isStandingQuarterly ||
      this.isStandingHalfYearly ||
      this.isStandingYearly
    );
  }

  get avatarInfo() {
    let activityTagSVG = `/icon/category/${this.beneficiary?.get('activityTag')}-m.svg`;
    let icon = null;

    if (this.isCompleted) {
      icon = 'status_approved';
    } else if (this.isDeclined || this.isCanceled) {
      icon = 'status_cancelled';
    } else if (
      this.isPendingReview ||
      this.isPendingSeizure ||
      this.isProcessing ||
      this.isValidated
    ) {
      icon = 'status_processing';
    } else if (
      this.isStandingProcessing ||
      this.isPending ||
      this.isKYBOnHold ||
      this.isBeneficiaryAccountCanceled ||
      this.isAccountCanceled
    ) {
      icon = 'status_scheduled';
    }

    return {
      icon,
      mediumLogo: this.enrichmentData.logo?.medium ?? activityTagSVG,
      smallLogo: this.enrichmentData.logo?.small ?? activityTagSVG,
    };
  }

  get shouldHaveAttachments() {
    let isAboveMaxAmount = amount => {
      let maxAmountInCents =
        this.organizationManager.organization.transferSettings?.max_amount_without_attachment_cents;
      let maxAmount = maxAmountInCents / 100;

      return amount >= maxAmount;
    };

    let isFx = this.fx || this.isFxScheduled;

    return (isAboveMaxAmount(this.amount) && this.isExternal) || isFx;
  }

  get isIBANObfuscated() {
    if (!this.iban) {
      return false;
    }
    return /^(?:••••|xxxx)/i.test(this.iban);
  }

  get attachmentsFiles() {
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  // This property won't behave correctly for employees since they don't have access to the iban
  // It is only to be used when creating transfers only since employees cannot initiate transfers
  get isExternal() {
    if (this.fx) {
      return true;
    }

    let matchingBankAccount = this.bankAccount.organization.bankAccounts.find(
      account => account.iban === this.iban
    );

    return !matchingBankAccount || matchingBankAccount.isExternalAccount;
  }

  get isBookTransfer() {
    return Boolean(this.creditBankAccountId);
  }

  @dependentKeyCompat
  get shouldValidateMaxAmount() {
    if (this.fx || this.wasScheduled || this.isPayLater) {
      return false;
    }

    return this.abilities.can('see balance bankAccount');
  }

  get isFxToChinaWithCNY() {
    let isChinaSelected = this.country === 'CN';
    let isCNYselected = this.localAmountCurrency === 'CNY';
    return isCNYselected && isChinaSelected;
  }

  get occurrencesCount() {
    if (!this.isStanding) {
      return null;
    }

    let countOccurrences = unit => {
      // dayjs rounds to 0, not to nearest integer
      return dayjs(this.standingEndingDate).diff(this.scheduledDate, unit) + 1;
    };

    if (this.isStandingWeekly) {
      return countOccurrences('weeks');
    }

    if (this.isStandingMonthly) {
      return countOccurrences('months');
    }

    if (this.isStandingQuarterly) {
      return countOccurrences('quarters');
    }

    if (this.isStandingHalfYearly) {
      return Math.round(countOccurrences('quarters') / 2);
    }

    return countOccurrences('years');
  }

  get wasScheduled() {
    if (this.isPayLater) {
      return false;
    }

    if (!this.isScheduled) {
      return true;
    }

    let startDate = dayjs(this.createdAt).startOf('day');
    let endDate = dayjs(this.scheduledDate).startOf('day');

    return startDate.diff(endDate, 'day') !== 0;
  }

  get shouldValidateBankAccountBalance() {
    let canSeeBalance = this.abilities.can('see balance bankAccount');

    return Boolean(this.fx && canSeeBalance);
  }

  get isRepeatable() {
    if (!COMPLETED_STATUS.includes(this.status)) {
      return false;
    }

    return !(
      this.isFx ||
      this.isFxScheduled ||
      this.isStanding ||
      (this.isDeclined && this.declinedReason !== 'insufficient_funds') ||
      this.isBookTransfer ||
      this.isBeneficiaryAccountCanceled ||
      this.isAccountCanceled ||
      this.isPendingSeizure ||
      this.isInternationalOut
    );
  }

  get isInternationalOut() {
    return this.operationType === OPERATION_TYPES.INTERNATIONAL_OUT;
  }

  get isPayLater() {
    return this.operationType === 'pay_later';
  }

  @waitFor
  async generateProof() {
    let response = await apiAction(this, { method: 'PUT', path: 'generate_proof' });
    this.store.pushPayload(response);
  }

  @waitFor
  async cancel() {
    try {
      let response = await apiAction(this, {
        method: 'PUT',
        path: 'cancel',
      });

      // the response from the request only updates the `transfer` resource
      // in the Ember Data store, but on the backend the `transaction` resource
      // also changed due to this action.
      //
      // we adjust the response here to also update the `transaction` resource
      // in the Ember Data store. if the resource can not be found there is
      // nothing to update so we can ignore it.
      let transaction = this.findTransaction();
      if (transaction) {
        response.transaction = {
          id: transaction.id,
          declined_reason: 'user_canceled',
          status: 'declined',
        };
      }

      this.store.pushPayload(response);
    } catch (error) {
      // if the backend replies with an error that the transfer is already
      // canceled we will update our copy of the transfer and
      // transaction accordingly
      if (error.code === ERROR_ALREADY_CANCELED) {
        let payload = {
          transfer: {
            id: this.id,
            cancelable: false,
            status: 'canceled',
          },
        };

        let transaction = this.findTransaction();
        if (transaction) {
          payload.transaction = {
            id: transaction.id,
            declined_reason: 'user_canceled',
            status: 'declined',
          };
        }

        this.store.pushPayload(payload);
      }

      // if the backend replies with an error that the transfer can not be
      // canceled anymore we will update our copy of the transfer accordingly
      if (error.code === ERROR_CANNOT_CANCEL) {
        this.store.pushPayload({
          transfer: {
            id: this.id,
            cancelable: false,
          },
        });
      }

      throw error;
    }
  }

  @waitFor
  async confirm() {
    let data = { transfer: this.serialize() };
    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'confirm',
        data,
      });
      return {
        errors: Array.isArray(response.errors) ? response.errors : [],
        warnings: Array.isArray(response.warnings) ? response.warnings : [],
        fees: response.estimated_price,
        instantAvailable: response?.instant_available,
        instantPayLaterAvailable: response?.instant_pay_later_available,
        spendLimits: response?.spend_limits,
      };
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  changeOperationType(type) {
    this.operationType = type;

    if (this.id) {
      return;
    }

    if (type === 'scheduled' || this.fx) {
      this.setProperties({
        standingEndingDate: null,
        standingOn: null,
      });
    }
  }

  findTransaction() {
    let transactions = this.store.peekAll('transaction');
    return transactions.find(it => it.subjectId === this.id && it.subjectType === 'Transfer');
  }

  addIdempotencyKey() {
    this.idempotencyKey = crypto.randomUUID();
  }

  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.idempotencyKey);
  }

  removeIdempotencyHeader() {
    this.networkManager.removeIdempotencyHeader();
  }

  unlinkAttachment(attachments) {
    return this.unlinkAttachmentsTask.perform(attachments);
  }

  unlinkAttachmentsTask = dropTask(async attachments => {
    let data = { transfer: { attachment_ids: attachments.map(it => it.id) } };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'unlink_attachments', data });
      this.store.pushPayload(response);
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  });
}
