import Model, { attr } from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  /** @type {string} */
  @attr last4;
  /** @type {string} */
  @attr expDate;
  /** @type {string} */
  @attr type;
  /** @type {string} */
  @attr organizationId;
}
