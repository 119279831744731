import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import RemindersConfigurationRuleValidations from 'qonto/validations/reminders-configuration';

export default class RemindersConfigurationModel extends Model.extend(
  RemindersConfigurationRuleValidations
) {
  @attr('comma-separated-string') sendTo;
  @attr('boolean') copyToSelf;

  @belongsTo('client-hub', { async: false, inverse: null }) client;
  @belongsTo('organization', { async: false, inverse: null }) organization;
  @hasMany('reminders-configuration/rule', {
    async: false,
    inverse: 'remindersConfiguration',
  })
  rules;

  get visibleRules() {
    return this.rules.filter(rule => !rule.isDeleted);
  }
}
