import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import titleize from 'qonto/utils/titleize';
import InviteValidations from 'qonto/validations/invite';

export default class InviteModel extends Model.extend(InviteValidations) {
  @service intl; //this service is used in the validator
  @service organizationManager;

  @attr('boolean', { defaultValue: true }) documentsToUpload;

  // read only
  @attr('string') cbs;
  @attr('string') organizationName;
  @attr() organizationLegalCountry;
  @attr() organizationAvatarThumb;
  @attr() organizationAvatar;
  @attr() organizationDefaultAvatarThumb;
  @attr() organizationDefaultAvatar;
  @attr() suggested;
  @attr('string') recipientId;
  @attr('date') birthdate;
  @attr('string') loginToken;
  @attr('string') italianFiscalCode;

  @belongsTo('team', { async: true, inverse: null }) team;
  @belongsTo('address', { async: false, inverse: null }) address;
  @belongsTo('organization', { async: false, inverse: null }) organization;
  @hasMany('document', { async: false, inverse: 'invite' }) documents;

  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') email;
  @attr('string') phoneNumber;

  /**
   * Spend limits params.
   * @type object {value: string, currency: string}
   */
  @attr currentMonthSpendings;
  @attr monthlyTransferLimit;
  @attr perTransferLimit;

  @attr('string') nationality;
  @attr('string') role;

  @attr('file') avatar;
  @attr('file') avatarThumb;
  @attr('file') defaultAvatar;
  @attr('file') defaultAvatarThumb;

  @attr('boolean') kycRequired;

  @attr() customPermissions;

  @attr() allowedBankAccountsIds;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();
    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }
  }

  @waitFor
  async accept() {
    let data = { invite: this.serialize() };
    return await apiAction(this, { method: 'PUT', path: 'accept', data });
  }

  @dependentKeyCompat
  get proofOfIdentity() {
    return this.documents.find(item => get(item, 'docType') === 'poi');
  }
}
