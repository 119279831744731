import Model, { attr, belongsTo } from '@ember-data/model';

export default class VatModel extends Model {
  /** @type {{ value: string, currency: ?string }} */
  @attr amount;

  /** @type {{ value: string, currency: ?string }} */
  @attr amountExcludingVat;

  /** @type {number} */
  @attr rate;

  /** @type {Transaction} */
  @belongsTo('transaction', { async: false, inverse: null }) transaction;
}
