import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';

import { Addons } from 'qonto/constants/addons';

export default class SubscriptionProduct extends Model {
  @attr('string') productType;
  @attr('string') code;
  @attr('string') groupCode;
  @attr('string') lineup;
  @attr('string') description;
  @attr('boolean') descriptionHasDecoration;
  @attr('string') subDescription;
  @attr('hash') monthlyPrice;
  @attr('hash') annualPrice;
  @attr('hash') benefitGroups;
  @attr('hash') featureGroups;
  @attr features;

  @attr productCompatibilities;

  @attr('boolean') disabled;
  @attr('boolean') isAvailable;
  @attr('string') iconLightUrl;
  @attr('string') iconDarkUrl;

  @service intl;

  hasFeature(featureCode) {
    return Boolean(this.features?.find(({ code }) => code === featureCode));
  }

  getLimitByFeatureCode(featureCode) {
    return this.features?.find(({ code }) => code === featureCode)?.limit;
  }

  get isAddon() {
    return this.productType === 'addon';
  }

  get localName() {
    return codeToName(this.intl, this.groupCode);
  }
}

export function codeToName(intl, groupCode) {
  switch (groupCode) {
    case Addons.AccountsPayable:
      return intl.t(
        'subscription.consumption-table.addon-overview.addon-list.accounts-payable.title'
      );
    case Addons.AccountsReceivable:
      return intl.t(
        'subscription.consumption-table.addon-overview.addon-list.accounts-receivable.title'
      );
    case Addons.ExpenseSpendManagement:
      return intl.t('subscription.consumption-table.addon-overview.addon-list.expense-spend.title');
    case 'solo_basic':
      return intl.t('subscription.change.plans.basic');
    case 'solo_smart':
      return intl.t('subscription.change.plans.smart');
    case 'solo_premium':
      return intl.t('subscription.change.plans.premium');
    case 'team_essential':
      return intl.t('subscription.change.plans.essential');
    case 'team_business':
      return intl.t('subscription.change.plans.business');
    case 'team_enterprise':
      return intl.t('subscription.change.plans.enterprise');
    default:
      return null;
  }
}
