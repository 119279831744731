import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
import { NetworkManagerError } from 'qonto/services/network-manager';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import { multiply, round } from 'qonto/utils/receivable-invoicing';

/**
 * @typedef DiscountHash
 * @type {object}
 * @property {string} amount
 * @property {string} value
 * @property {string} type
 */

export default class BaseInvoicingDocumentModel extends Model {
  @service networkManager;

  /** @type {DiscountHash} */
  @attr('hash') discount;
  /** @type {string} */
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  /** @type {string} */
  @attr locale;
  /** @type {string} */
  @attr beneficiaryName;
  /** @type {string} */
  @attr contactEmail;
  /** @type {string} */
  @attr termsAndConditions;
  /** @type {string} */
  @attr stampDutyAmount;
  /** @type {string} */
  @attr header;
  /** @type {string} */
  @attr footer;
  /** @type {string} */
  @attr amountDue;
  /** @type {Date} */
  @attr updatedAt;
  /** @type {Date} */
  @attr createdAt;
  /** @type {string} */
  @attr status;

  /** @type {WelfareFund} */
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Customer} */
  @belongsTo('client-hub', { async: true, inverse: null }) customer;
  /** @type {Organization} */
  @belongsTo('organization', { async: false, inverse: null }) organization;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  @waitFor
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  clearItemsWithNoId() {
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }

  _handleError(error) {
    if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
      let errors = errorsArrayToHash(error.errors, { useErrorCode: true });
      this.networkManager.errorModelInjector(this, errors);
    }

    if (error.isAdapterError || error instanceof NetworkManagerError) {
      this._assignRelationshipErrors(error);
    }

    throw error;
  }

  _assignRelationshipErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    let parsedErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    let parsedRelationshipErrors = this.errors.reduce(
      (errorsForRelationships, relationshipError) => {
        let [invoiceAttr, attribute] = relationshipError.attribute.split('/');
        if (
          invoiceAttr === 'payment' ||
          invoiceAttr === 'welfareFund' ||
          invoiceAttr === 'withholdingTax'
        ) {
          errorsForRelationships[invoiceAttr] = errorsForRelationships[invoiceAttr] || {};
          errorsForRelationships[invoiceAttr][attribute] =
            errorsForRelationships[invoiceAttr][attribute] || [];
          errorsForRelationships[invoiceAttr][attribute].push(relationshipError.message);
        }
        return errorsForRelationships;
      },
      {}
    );

    Object.entries(parsedErrors).forEach(([index, parsedErrorsForItem]) => {
      this.networkManager.errorModelInjector(this.items[index], parsedErrorsForItem, error);
    });

    Object.entries(parsedRelationshipErrors).forEach(([attribute, parsedRelationshipError]) => {
      if (attribute === 'payment') {
        this.networkManager.errorModelInjector(this.payment, parsedRelationshipError, error);
      }
      if (attribute === 'welfareFund') {
        this.networkManager.errorModelInjector(this.welfareFund, parsedRelationshipError, error);
      }
      if (attribute === 'withholdingTax') {
        this.networkManager.errorModelInjector(this.withholdingTax, parsedRelationshipError, error);
      }
    });
  }

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed 
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseDiscountedTotalExcludingVat);
    }, 0);
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat() {
    return round(this.preciseTotalExcludingVat, 100).toFixed(2);
  }

  get totalAmount() {
    let totalAmount =
      parseFloat(this.preciseTotalVat) +
      parseFloat(this.preciseTotalExcludingVat) +
      parseFloat(this.preciseWelfareFundAmount) -
      parseFloat(this.preciseWithholdingTaxAmount);
    return totalAmount.toFixed(2);
  }

  get preciseTotalVatWithoutWelfareFund() {
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseTotalVat);
    }, 0);
  }

  get totalVatWithoutWelfareFund() {
    return round(this.preciseTotalVatWithoutWelfareFund, 100).toFixed(2);
  }

  get preciseTotalVat() {
    return (
      parseFloat(this.preciseTotalVatWithoutWelfareFund) +
      parseFloat(this.preciseWelfareFundVatAmount)
    );
  }

  get totalVat() {
    return round(this.preciseTotalVat, 100).toFixed(2);
  }

  get preciseWelfareFundVatAmount() {
    return this.welfareFund?.rate
      ? multiply(
          parseFloat(this.preciseTotalVatWithoutWelfareFund),
          parseFloat(this.welfareFund.rate)
        )
      : '0.00';
  }

  get welfareFundVatAmount() {
    return round(this.preciseWelfareFundVatAmount, 100).toFixed(2);
  }

  get preciseWelfareFundAmount() {
    return this.welfareFund?.rate
      ? multiply(parseFloat(this.preciseTotalExcludingVat), parseFloat(this.welfareFund.rate))
      : '0.00';
  }

  get welfareFundAmount() {
    return round(this.preciseWelfareFundAmount, 100).toFixed(2);
  }

  get preciseWithholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return multiply(
        parseFloat(this.preciseTotalExcludingVat) + parseFloat(this.preciseWelfareFundAmount),
        parseFloat(this.withholdingTax.rate)
      );

    return multiply(
      parseFloat(this.preciseTotalExcludingVat),
      parseFloat(this.withholdingTax.rate)
    );
  }

  get withholdingTaxAmount() {
    return round(this.preciseWithholdingTaxAmount, 100).toFixed(2);
  }
}
