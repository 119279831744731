import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
import { getSupportedUnits } from 'qonto/utils/receivable-invoicing';

export default class ProductModel extends Model {
  @service intl;

  @attr('string') title;
  @attr('string') description;

  /** @type { value: string, currency: string } **/
  @attr('hash', {
    defaultValue: () => {
      return { value: undefined, currency: CURRENCIES.default };
    },
  })
  unitPrice;
  @attr('string') vatRate;
  @attr('string') vatExemptionCode;
  @attr('string') unit;

  /** @type "good" | "service" **/
  @attr('string', { defaultValue: 'service' }) type;

  /** @type Array<{ title: string, url: string }> **/
  @attr({ defaultValue: () => [] }) links;
  @attr('string') internalNote;

  @tracked isSelected = false;

  get formattedUnit() {
    if (!this.unit) {
      return '-';
    }
    let standardUnit = getSupportedUnits(this.intl).find(({ unitCode }) => unitCode === this.unit);
    return standardUnit?.unitLongName ?? standardUnit?.unitName ?? this.unit;
  }
}
