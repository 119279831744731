import { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

import BaseReceivableInvoiceModel from './receivable-invoice/invoice-base';

export default class ReceivableCreditNoteModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  @attr number;
  /** @type {string} YYYY-MM-DD */
  @attr('string', { defaultValue: () => dayjs().format(DATE_PICKER_FIELD_FORMAT) })
  issueDate;
  /** @type {string} */
  @attr reason;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  @attr einvoicingStatus;

  /** @type {ReceivableInvoice} */
  @belongsTo('receivable-invoice', { async: true, inverse: 'receivableCreditNotes' })
  receivableInvoice;
  /** @type {Atttachment} */
  @belongsTo('attachment', { async: false, inverse: null }) attachment;

  /** @type {WelfareFund} */
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Item} */
  @hasMany('receivable-invoice/item', { async: false, inverse: null }) items;

  @service networkManager;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  get totalAmount() {
    let totalAmount =
      parseFloat(this.totalVat) +
      parseFloat(this.totalExcludingVat) +
      parseFloat(this.welfareFundAmount) +
      parseFloat(this.withholdingTaxAmount);
    return totalAmount.toFixed(2);
  }

  get withholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return (
        (parseFloat(this.totalExcludingVat) + parseFloat(this.welfareFundAmount)) *
        parseFloat(-this.withholdingTax.rate)
      ).toFixed(2);

    return (parseFloat(this.totalExcludingVat) * parseFloat(-this.withholdingTax.rate)).toFixed(2);
  }

  get pdfUrl() {
    return this.store.adapterFor('receivable-credit-note').urlForPdf(this.id);
  }

  @waitFor
  async setPdfPreviewIframeUrl() {
    if (!this.pdfUrl) return;

    let reader = new FileReader();
    let handler = () => {
      this.pdfPreviewIframeUrl = reader.result;
    };

    try {
      let response = await this.networkManager.rawRequest(this.pdfUrl, { method: 'GET' });
      let blob = await response.blob();

      reader.addEventListener('load', handler);
      reader.readAsDataURL(blob);
    } catch {
      this.pdfPreviewIframeUrl = null;
      reader.removeEventListener(handler);
    }
  }
}
