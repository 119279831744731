import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

export default class AttachmentModel extends Model {
  @attr('string') slug;
  @attr('string') downloadUrl;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  createdAt;
  @attr('file') file;
  @attr('file') probativeAttachment;
  @attr('file') thumbnail;
  @attr('string') attachmentType;

  @equal('probativeAttachment.status', 'available') isProbated;

  vatStatus = null;
}
