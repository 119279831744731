import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class Statement extends Model {
  @service intl;

  @attr authorizedEmails;
  @attr month;
  @attr note;
  @attr notifyByEmail;
  @attr pdfUrl;
  @attr slug;
  @attr withAttachments;
  @attr year;
  @attr zipUrl;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  get date() {
    return new Date(this.year, this.month - 1, 1);
  }

  get name() {
    try {
      let date = dateToken({
        date: this.date,
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
      });

      if (this.bankAccount.name) {
        return `${date} – ${this.bankAccount.name}`;
      }

      return date;
    } catch {
      return this.slug;
    }
  }
}
