import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';

import { dropTask } from 'ember-concurrency';
import Modifier from 'ember-modifier';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { parseContentDisposition } from 'qonto/utils/parse-content-disposition';

let waiter = buildWaiter('download-with-headers');

function cleanup(instance) {
  if (instance.objectURL) URL.revokeObjectURL(instance.objectURL);
  if (instance.element) instance.element.removeEventListener('click', instance.onClickHandler);

  instance.element = null;
  instance.objectURL = null;
}

export default class DownloadWithHeadersModifier extends Modifier {
  @service networkManager;
  @service errors;
  @service intl;
  @service toastFlashMessages;

  objectURL;
  element;

  constructor(...args) {
    super(...args);
    registerDestructor(this, cleanup);
  }

  /**
   * @param {HTMLAnchorElement} element
   */
  modify(element) {
    if (!(element instanceof HTMLAnchorElement)) return;

    this.element = element;
    this.element.addEventListener('click', this.onClickHandler);
  }

  @action
  onClickHandler(event) {
    if (!this.objectURL) {
      event.preventDefault();
      let originalURL = this.element.href;
      this.createObjectURLTask
        .perform(originalURL)
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(
            this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
          );
        })
        .finally(() => {
          this.element.href = originalURL;
        });
      return;
    }
    event.stopImmediatePropagation();
  }

  createObjectURLTask = dropTask(async target => {
    let token = waiter.beginAsync();

    try {
      let response = await this.networkManager.rawRequest(target, {
        method: 'GET',
      });

      this.element.download = decodeURIComponent(
        parseContentDisposition(response.headers).get('filename')
      );

      let blob = await response.blob();
      this.objectURL = URL.createObjectURL(blob);

      this.element.href = this.objectURL;
      this.element.click();
      this.objectURL = null;
    } finally {
      waiter.endAsync(token);
    }
  });
}
