import Model, { attr, hasMany } from '@ember-data/model';

export default class ConnectionModel extends Model {
  @attr organizationId;
  @attr applicationId;
  @attr activationId;
  @attr externalService;
  @attr params;
  @hasMany('hub-notification-rules', { async: true, inverse: 'connection' }) rules;
}
