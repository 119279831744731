import { attr, belongsTo, hasMany } from '@ember-data/model';

import { STATUS } from 'qonto/constants/requests';
import Request from 'qonto/models/request';

export default class RequestMultiDirectDebitCollectionModel extends Request {
  @attr('string') note;
  @attr('string') status;
  @attr('string') operationType;
  @attr('string', { defaultValue: 'multi_direct_debit_collection' }) requestType;
  @attr totalAmount;
  @attr('number') totalCount;
  @attr('date') createdAt;
  @attr('date') processedAt;

  @belongsTo('membership', { async: false, inverse: null }) initiator;
  @belongsTo('membership', { async: false, inverse: null }) approver;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  @hasMany('request-direct-debit-collection', {
    async: false,
    inverse: 'requestMultiDirectDebitCollection',
  })
  requestDirectDebitCollections;

  get isPending() {
    return this.status === STATUS.PENDING;
  }

  get isApproved() {
    return this.status === STATUS.APPROVED;
  }

  get isRejected() {
    return this.status === STATUS.DECLINED;
  }
}
