import Model, { attr } from '@ember-data/model';

export default class F24InpsTaxItemModel extends Model {
  @attr branchCode;
  @attr taxType;
  @attr inpsCode;
  @attr referencePeriodFrom;
  @attr referencePeriodTo;
  @attr('number') taxAmount;
  @attr('number') compensationAmount;
}
