import Model, { attr } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import Validations from 'qonto/validations/concierge-request';

export default class ConciergeRequestModel extends Model.extend(Validations) {
  @attr channel;
  @attr inquiry;

  @waitFor
  async send() {
    return await apiAction(this, {
      requestType: 'createRecord',
      method: 'POST',
      data: { channel: this.channel, content: this.inquiry },
    });
  }
}
