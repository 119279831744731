import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import AddressValidations from 'qonto/validations/address';

export default class AddressModel extends Model.extend(AddressValidations) {
  @service intl; //this service is used in validator
  @attr('string') contactName;
  @attr('string') firstLine;
  @attr('string') secondLine;
  @attr('string') thirdLine;
  @attr('string') zipcode;
  @attr('string') city;
  @attr('string') country;

  get fullAddress() {
    let addressFirstPart = [this.firstLine, this.secondLine, this.thirdLine, this.zipcode]
      .filter(part => !isEmpty(part))
      .join(', ');
    let addressSecondPart = [this.city, this.country].filter(part => !isEmpty(part)).join(', ');

    return `${addressFirstPart} ${addressSecondPart}`;
  }
}
