import Model, { attr, belongsTo } from '@ember-data/model';

import { ONBOARDING_STEP_STATUS } from 'qonto/constants/receivable-invoice';
/** Onboarding status for current organization
 * @typedef OnboardingStatus
 * @type {("not_eligible" | "not_started" | "started" | "completed" | "skipped")}
 * */

/** Specific onboarding step status
 * @typedef {("to_do"|"prefilled"|"confirmed")} OnboardingStep
 * */

/**
 * @typedef PrefillHash
 * @type {object}
 * @property {?string} vat_number
 * @property {?string} commercial_register_number
 * @property {?number} legal_capital_share
 * @property {?string} invoice_numbering_pattern
 * @property {?string} invoice_next_number
 * @property {?string} discount_conditions
 * @property {?string} late_payment_penalties
 * @property {?string} legal_fixed_compensation
 * @property {?string} invoice_header
 * @property {?string} invoice_footer
 */

export default class AccountsReceivableOnboardingModel extends Model {
  /** @type {OnboardingStatus} */
  @attr status;

  /** @type {OnboardingStep} */
  @attr stepStatusNumbering;

  /** @type {OnboardingStep} */
  @attr substepStatusInvoiceNumbering;

  /** @type {OnboardingStep} */
  @attr stepStatusCompanyDetails;

  /** @type {OnboardingStep} */
  @attr substepStatusCompanyDetails;

  /** @type {OnboardingStep} */
  @attr substepStatusTermsAndConditions;

  /** @type {OnboardingStep} */
  @attr stepStatusCustomization;

  /** @type {OnboardingStep} */
  @attr substepStatusLogo;

  /** @type {OnboardingStep} */
  @attr substepStatusColors;

  /** @type {OnboardingStep} */
  @attr substepStatusCustomMessages;

  /** @type {OnboardingStep} */
  @attr stepStatusAccountantAccess;

  /** @type {?string} */
  @attr prefilledAt;

  /** @type {PrefillHash} */
  @attr('hash') prefillData;

  @belongsTo('organization', { async: false, inverse: null }) organization;

  get areMandatoryStepsCompleted() {
    return (
      this.stepStatusNumbering === ONBOARDING_STEP_STATUS.CONFIRMED &&
      this.stepStatusCompanyDetails === ONBOARDING_STEP_STATUS.CONFIRMED
    );
  }

  get hasManuallyCompletedSteps() {
    if (this.prefilledAt) return false;

    return [
      this.stepStatusNumbering,
      this.stepStatusCompanyDetails,
      this.stepStatusCustomization,
      this.stepStatusAccountantAccess,
    ].includes(ONBOARDING_STEP_STATUS.CONFIRMED);
  }
}
