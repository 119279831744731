import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class ExternalBankRecommendationsBundle extends Model {
  @attr('string') bankName;
  @attr('hash') logo;
  @attr('string') status;

  @hasMany('external-bank-recommendation', {
    async: false,
    inverse: 'externalBankRecommendationsBundle',
  })
  recommendations;

  @belongsTo('organization', { async: false, inverse: null }) organization;
}
