import Model, { attr, belongsTo } from '@ember-data/model';

export default class F24PayerModel extends Model {
  @attr firstName;
  @attr lastName;
  @attr businessName;
  @attr('boolean') isDifferentYear;
  @attr fiscalCode;
  @attr birthDate;
  @attr birthPlace;
  @attr birthProvince;
  @attr sex;
  @attr otherRelatedFiscalCode;
  @belongsTo('f24/address', { async: false, inverse: null }) address;
}
