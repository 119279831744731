import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';
import { reads } from 'macro-decorators';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { getCurrentParisDateString } from 'qonto/utils/date';

export default class MultiTransferModel extends Model {
  @service networkManager;

  @attr('string', { defaultValue: getCurrentParisDateString }) scheduledDate;
  @attr('file') file;
  @attr('string') slug;
  @attr('string') status;
  @attr('number') totalAmount;
  @attr('number') totalAmountCents;
  @attr('string') activityTag;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  @belongsTo('organization', { async: true, inverse: null }) organization;

  @hasMany('bulkTransfers', { async: false, inverse: 'multiTransfer' }) transfers;

  @reads('selectedTransfers.length') selectedTransfersCount;

  idempotencyKey = null;
  scheduled = false;
  confirmWarnings = null;

  get minDate() {
    return dayjs().add(1, 'day').format(DATE_PICKER_FIELD_FORMAT);
  }

  get transfersAmount() {
    let transfers = this.selectedTransfers;
    let sum = (accumulator, transfer) =>
      parseFloat(accumulator) + parseFloat(transfer.get('amount'));
    return transfers ? transfers.reduce(sum, 0) : 0;
  }

  get selectedTransfers() {
    return this.transfers.filter(item => get(item, 'selected') === true);
  }

  addIdempotencyKey() {
    this.idempotencyKey = crypto.randomUUID();
  }

  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.idempotencyKey);
  }

  removeIdempotencyHeader() {
    this.networkManager.removeIdempotencyHeader();
  }

  @waitFor
  async confirm() {
    let data = { multi_transfer: this.serialize() };
    let response = await apiAction(this, { method: 'POST', path: 'confirm', data });
    if (response.multi_transfer) {
      let normalizedRecord = this._normalizedMultiTransfer(response);
      return this.store.push(normalizedRecord);
    }
    return response;
  }

  @waitFor
  async submit() {
    let data = { multi_transfer: this.serialize() };
    let response = await apiAction(this, { method: 'PUT', path: 'submit', data });
    let normalizedRecord = this._normalizedMultiTransfer(response);
    return this.store.push(normalizedRecord);
  }

  _normalizedMultiTransfer(transfer) {
    let multiTransferId = transfer.multi_transfer.id;

    // the backend is not sending IDs for the individual transfers in a
    // multi-transfer, but since we are transforming them to Ember Data records
    // they will need unique IDs. the `bulk_id` looks like an ID but is only
    // unique within the uploaded file and not globally unique. we can however
    // combine it with the ID of the parent multi-transfer to get a globally
    // unique ID, which we do here for all the transfers in the response.
    for (let _transfer of transfer.multi_transfer.transfers) {
      _transfer.id = `${multiTransferId}#${_transfer.bulk_id}`;
    }

    let serializer = this.store.serializerFor('multi-transfer');
    return serializer.normalizeSingleResponse(
      this.store,
      this.constructor,
      transfer,
      null,
      'findRecord'
    );
  }
}
