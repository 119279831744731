import { attr, belongsTo, hasMany } from '@ember-data/model';

import Request from 'qonto/models/request';

export default class RequestMileageModel extends Request {
  @attr amount;

  @attr createdAt;
  @hasMany('attachment', { async: true, inverse: null }) attachments;
  @attr('string', { defaultValue: 'mileage' }) requestType;
  @attr('string', { defaultValue: 'mileage' }) reference;
  @attr note;
  @attr iban;
  @attr declinedNote;
  @attr('string', { defaultValue: 'pending' }) status;

  /** @type {string} YYYY-MM-DD */
  @attr tripDate;
  @attr vehicle;
  @attr departure;
  @attr arrival;
  @attr('boolean') roundTrip;
  @attr('string') distanceMeters;
  @attr mileageCalculation;
  @attr requestId;

  @belongsTo('membership', { async: true, inverse: null }) initiator;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @hasMany('label', { async: false, inverse: null }) labels;
}
